import React, { useEffect } from "react";
import { useState } from "react";
import { FaHeart, FaShoppingCart } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import storage from "redux-persist/lib/storage";
import { addCartItem } from "../../actions/cart.action";
import {
  addItemToWishlist,
  removeItemFromWishlist,
  setWishlistItemFromStorage,
} from "../../actions/wishlist.action";
import { GET_PRODUCT_DETAILS } from "../../apiConst";
import BackButton from "../../Common/Back";
import { saveDataInStorage } from "../../utils";
import { getRequest } from "../interceptor/interceptor";
import FullScreenLoader from "../Loader/FullScreenLoader";
import ProductSlider from "./ProductSlider";

function ProductDetails() {
  const [productId, setProductId] = useState(null);
  const [productData, setProductData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [productCount, setProductCount] = useState(0);
  const [likeToggle, setLikeToggle] = useState(false);

  const cartproduct = useSelector((state) => state.cart);
  const wishListItems = useSelector((state) => state.wishlist.wishListItems);
  const userLoggedIn = useSelector((state) => state.login.isLoggedIn);

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (params) {
      const id = params.productId;
      setProductId(id);
    }
  }, [params]);

  useEffect(() => {
    if (wishListItems.length === 0) {
      storage.getItem("wishlist").then((res) => {
        if (res) {
          dispatch(setWishlistItemFromStorage(JSON.parse(res)));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (productId) {
      getProductDetails();
    }
  }, [productId]);

  const getProductDetails = () => {
    setIsLoading(true);

    getRequest(`${GET_PRODUCT_DETAILS}?id=${productId}`).then((res) => {
      console.log(res);
      if (res && res.data.length > 0) {
        setProductData(res.data[0]);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const incrementCount = () => {
    setProductCount(productCount + 1);
  };

  useEffect(() => {
    saveDataInStorage("wishlist", wishListItems);
    const found = wishListItems.find(
      (item) => item.productId === params.productId
    );
    if (found) {
      setLikeToggle(true);
    } else {
      setLikeToggle(false);
    }
  }, [wishListItems]);

  const decrementCount = () => {
    if (productCount === 0) {
      setProductCount(0);
    } else {
      setProductCount(productCount - 1);
    }
  };

  const favouriteToggle = (product) => {
    if (likeToggle) {
      dispatch(removeItemFromWishlist(product._id));
    } else {
      dispatch(
        addItemToWishlist({
          productId: product._id,
          quantity: 1,
        })
      );
    }
  };

  const addToCart = (product) => {
    dispatch(addCartItem(product, productCount));
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}

      <div>
        {productData && (
          <>
            <BackButton />
            <div className="grid  md:grid-cols-2  sm:grid-cols-1 xs:grid-cols-1">
              <div className="p-5">
                <ProductSlider productData={productData} />
              </div>
              <div className="p-5">
                <div className="flex justify-between">
                  <h3 className="font-extrabold text-2xl font-headings">
                    {productData.title}
                  </h3>
                  {userLoggedIn && (
                    <div className="mr-5 text-xl" title="Add to Favourites">
                      <FaHeart
                        onClick={() => favouriteToggle(productData)}
                        color={likeToggle ? "red" : "gray"}
                      />
                    </div>
                  )}
                </div>
                <div className="mt-3 font-paragraphs">
                  {productData.description}
                </div>
                <div className="mt-5 text-greenDark font-extrabold">
                  ${productData.price}
                </div>
                <div className="product-number-input h-10 w-32 mt-5">
                  <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                    <button
                      data-action="decrement"
                      className="rounded-full px-3 py-1.5 text-xl text-center bg-secondary-300"
                      onClick={decrementCount}
                    >
                      <span className="font-extrabold">−</span>
                    </button>
                    <input
                      type="number"
                      className="outline-none border-none focus:outline-none text-center w-full"
                      name="custom-input-number"
                      disabled={true}
                      value={productCount}
                    ></input>
                    <button
                      data-action="increment"
                      className="rounded-full px-3 py-1.5 text-xl text-center bg-secondary-300"
                      onClick={incrementCount}
                    >
                      <span className="m-auto font-extrabold">+</span>
                    </button>
                  </div>
                </div>
                <div className="w-full md:mt-36 sm:mt-5 xs:mt-5">
                  {productCount <= productData.quantity ? (
                    <button
                      className="rounded-xl px-8 w-full py-3 text-xl text-center bg-greenDark"
                      onClick={() => addToCart(productData)}
                    >
                      <span className="flex justify-center items-center font-extrabold text-white">
                        {cartproduct &&
                        cartproduct.length > 0 &&
                        cartproduct.find((item) => item._id === productData._id)
                          ? "Added to Cart"
                          : "Add to Cart"}
                        <span className="ml-2">
                          <FaShoppingCart />
                        </span>
                      </span>
                    </button>
                  ) : (
                    <button className="rounded-xl px-8 w-full py-3 text-xl text-center opacity-50 bg-greenDark pointer-events-none">
                      <span className="flex justify-center items-center font-extrabold text-white">
                        Out of Stock
                      </span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ProductDetails;
