import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  FaList,
  FaShoppingBag,
  FaShoppingCart,
  FaUser,
  FaUserAlt,
} from "react-icons/fa";
import * as te from "tw-elements";
import { removeLocalStorageData } from "../../../utils";
import AdminNavbar from "../Admin Navbar/AdminNavbar";
import { getRequest } from "../../interceptor/interceptor";
import { ADMIN_DASHBOARD_ENDPOINT } from "../../../apiConst";
import { get } from "lodash";
import FullScreenLoader from "../../Loader/FullScreenLoader";

function AdminDashboard() {
  const [countData, setCountData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const getAdminDashboardData = () => {
    setIsLoading(true);
    getRequest(`${ADMIN_DASHBOARD_ENDPOINT}`)
      .then((res) => {
        setIsLoading(false);
        if (get(res, "data")) {
          setCountData(get(res, "data"));
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAdminDashboardData();
  }, []);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className="p-4  rounded-lg dark:border-gray-700 mt-12 font-headings">
        <div className="flex flex-wrap -mx-3 mb-5">
          <div
            className="w-full max-w-full px-6 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4 cursor-pointer"
            onClick={() => navigate("/users")}
          >
            <div className="relative flex flex-col min-w-0 break-words border-t-2 border-gray-100 bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div className=" px-3">
                      <p className="mb-0  text-2xl font-extrabold leading-normal uppercase dark:text-white dark:opacity-60">
                        Users
                      </p>
                      <h5 className="mb-2 text-xl font-bold dark:text-white mt-2">
                        {get(countData, "usersCount")}
                      </h5>
                      {/* <p className="mb-0 dark:text-white dark:opacity-60">
                          <span className="text-sm font-bold leading-normal text-emerald-500">
                            +3%
                          </span>
                          since last week
                        </p> */}
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3 flex justify-center items-center">
                    <div className="inline-block w-16 h-16 text-center rounded-full bg-gradient-to-tl from-red to-orange-600">
                      <i className="flex justify-center items-center leading-none  text-xl relative top-[22px] text-white">
                        <FaUser />
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full max-w-full px-6 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4 cursor-pointer">
            <div className="relative flex flex-col min-w-0 break-words border-t-2 border-gray-100 bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div className=" px-3">
                      <p className="mb-0 text-2xl font-semibold leading-normal uppercase dark:text-white dark:opacity-60">
                        Orders
                      </p>
                      <h5 className="mb-2 text-xl font-bold dark:text-white mt-2">
                        {get(countData, "ordersCount")}
                      </h5>
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3 flex justify-center items-center">
                    <div className="inline-block w-16 h-16 text-center rounded-full bg-gradient-to-tl from-greenDark to-teal-400">
                      <i className="leading-none flex justify-center items-center text-xl relative top-[22px] text-white">
                        <FaShoppingCart />
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="w-full max-w-full px-6 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4 cursor-pointer"
            onClick={() => navigate("/all-products")}
          >
            <div className="relative flex flex-col min-w-0 break-words border-t-2 border-gray-100 bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div className=" px-3">
                      <p className="mb-0  text-2xl font-semibold leading-normal uppercase dark:text-gre dark:opacity-60">
                        Products
                      </p>
                      <h5 className="mb-2 text-xl font-bold dark:text-white mt-2">
                        {get(countData, "productsCount")}
                      </h5>
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3 flex justify-center items-center">
                    <div className="inline-block w-16 h-16 text-center rounded-full bg-gradient-to-tl from-purple to-blue-400">
                      <i className="ni leading-none flex justify-center items-center text-xl relative top-[22px] text-white">
                        <FaShoppingBag />{" "}
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="w-full max-w-full px-6 sm:w-1/2 sm:flex-none xl:w-1/4 cursor-pointer"
            onClick={() => navigate("/categories")}
          >
            <div className="relative flex flex-col min-w-0 break-words border-t-2 border-gray-100 bg-white shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div className=" px-3">
                      <p className="mb-0  text-2xl font-semibold leading-normal uppercase dark:text-white dark:opacity-60">
                        Categories
                      </p>
                      <h5 className="mb-2 text-xl font-bold dark:text-white mt-2">
                        {get(countData, "categoriesCount")}
                      </h5>
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3 flex justify-center items-center">
                    <div className="inline-block w-16 h-16 text-center rounded-full bg-gradient-to-tl from-orange-500 to-yellow-500">
                      <i className="ni leading-none flex justify-center items-center text-xl relative top-[22px] text-white">
                        <FaList />{" "}
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminDashboard;
