import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { toast } from "react-toastify";
import storage from "redux-persist/lib/storage";
import { CHECKOUT_API } from "../../apiConst";
import { createCashfreeOrder } from "../../cashfreeApi";
import { postRequest } from "../interceptor/interceptor";
import { Country, State, City } from "country-state-city";
import { useDispatch } from "react-redux";
import { emptyCart } from "../../actions/cart.action";

const Payment = ({
  grandTotal,
  userLoggedIn,
  userName,
  userEmail,
  userPhone,
  cartproduct,
  addressDetail,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    reset({
      paymentType: "COD",
    });
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setUserObj = async () => {
    if (userLoggedIn) {
      storage.getItem("login").then((res) => {
        res = JSON.parse(res);
        return res.user;
      });
    } else {
      return {
        name: userName,
        email: userEmail,
        phoneNumber: userPhone,
      };
    }
  };

  const payUsingCashFree = async () => {
    const user = {
      name: userName,
      email: userEmail,
      phoneNumber: userPhone,
    };
    console.log(user);
    const cashfreeOrderData = await createCashfreeOrder(
      grandTotal,
      "INR",
      user,
      cartproduct?.length === 1 ? cartproduct[0]?.title : "Multiple Product"
    );
    console.log("cashfree ", cashfreeOrderData);
  };

  const checkoutOrderApi = (paymentType) => {
    let payload = [];
    const addressData = addressDetail.addressData;
    cartproduct.forEach((product) => {
      let obj = {
        productId: product._id,
        price: product.price,
        quantity: product.quantity,
      };
      payload.push(obj);
    });

    const shippingAddress =
      `${addressData?.line1}${"\n"}${
        addressData?.line2.length > 0 ? addressData?.line2 : ""
      }${"\n"}${addressData?.city}, ${
        State.getStateByCodeAndCountry(addressData?.state, addressData?.country)
          ?.name
      }, ${addressData?.zipcode}${"\n"}${
        Country.getCountryByCode(addressData?.country)?.name
      }` || "";

    const checkoutObj = {
      items: payload,
      amount: grandTotal,
      discount: 0,
      payment_type: paymentType,
      country: addressData.country,
      status: "pending",
      city: addressData.city,
      zipcode: addressData.zipcode,
      shippingAddress: shippingAddress,
    };

    console.log(checkoutObj);
    postRequest(`${CHECKOUT_API}`, checkoutObj).then((res) => {
      navigate("/orderConfirmation");
      dispatch(emptyCart());
    });
  };

  const onSubmitOrder = (data) => {
    const { paymentType } = data;
    if (!isEmpty(addressDetail.addressData)) {
      if (paymentType === "COD") {
        checkoutOrderApi("cod");
      } else {
        payUsingCashFree();
      }
    } else {
      toast.error("Please Add Address");
    }
  };

  return (
    <div className="w-full">
      <form className="flex w-full justify-around p-3">
        <div class="mb-[0.125rem] mt-4 mr-4 text-lg inline-block min-h-[1.5rem] pl-[1.5rem]">
          <input
            class="relative float-left -ml-[1.5rem] mr-1 mt-1 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
            type="radio"
            name="paymentType"
            value="COD"
            {...register(`paymentType`, {
              required: "Please Select Active Status",
            })}
          />
          <label
            class="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
            for="COD"
          >
            Cash On Delivery
          </label>
        </div>

        <div class="mb-[0.125rem] mt-4 text-lg mr-4 inline-block min-h-[1.5rem] pl-[1.5rem]">
          <input
            class="relative float-left -ml-[1.5rem] mr-1 mt-1 h-5 w-5 appearance-none rounded-full border-2 border-solid border-neutral-300 before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:border-neutral-600 dark:checked:border-primary dark:checked:after:border-primary dark:checked:after:bg-primary dark:focus:before:shadow-[0px_0px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:border-primary dark:checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca]"
            type="radio"
            name="paymentType"
            {...register(`paymentType`, {
              required: "Please Select Active Status",
            })}
            value="cashfree"
          />
          <label
            class="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
            for="cashfree"
          >
            Pay Using Cashfree
          </label>
        </div>

        <div className="w-1/3 max-w-xl">
          <button
            type="submit"
            onClick={handleSubmit(onSubmitOrder)}
            className="text-base font-extrabold font-headings leading-none w-full rounded-3xl py-5 bg-greenDark border-greenDark border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green text-white"
          >
            Submit Order
          </button>
        </div>
      </form>
    </div>
  );
};

export default Payment;
