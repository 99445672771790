import { useEffect, useState } from "react";
import {
  FaEdit,
  FaPlus,
  FaRegUserCircle,
  FaTrashAlt,
  FaUserCircle,
} from "react-icons/fa";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { CATEGORY_ENDPOINT, GET_ALL_USERS } from "../../../apiConst";
import { deleteRequest, getRequest } from "../../interceptor/interceptor";
import FullScreenLoader from "../../Loader/FullScreenLoader";

const DisplayCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const navigate = useNavigate();

  const getCategories = () => {
    setIsLoading(true);
    getRequest(`${CATEGORY_ENDPOINT}`)
      .then((res) => {
        if (res) {
          setCategories(res?.categories || []);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleAdd = () => {
    navigate("/add-category");
  };

  const handleEdit = (category) => {
    navigate("/add-category", {
      state: {
        ...category,
        isEdit: true,
      },
    });
  };

  const handleDelete = (id) => {
    setIsLoading(true);
    deleteRequest(`${CATEGORY_ENDPOINT}?id=${id}`)
      .then((res) => {
        toast.success("Category Deleted Successfully");
        setIsLoading(false);
        getCategories();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className="flex w-full md:flex-row xs:flex-col font-headings leading-7 lg:leading-9">
        <div className="flex justify-center w-3/12">
          <button
            className="mx-1 mb-0 flex rounded border-2 border-greenDark px-6 pt-2 pb-[6px] text-sm font-medium uppercase leading-normal text-greenDark transition duration-150 ease-in-out hover:border-greenDark  hover:bg-greenDark hover:text-white  focus:outline-none focus:ring-0 active:border-greenDark active:text-white "
            onClick={() => handleAdd()}
          >
            <span className="mr-2 mt-[0.10rem]">
              <FaPlus />
            </span>
            Add Category
          </button>
        </div>
        <div className="flex justify-center w-6/12 items-center space-y-2 flex-col">
          <h1 className="text-3xl lg:text-4xl font-semibold font-headings text-greenDark  ">
            All Categories
          </h1>
        </div>
        <div className="w-3/12"></div>
      </div>
      <div className="m-2 p-5">
        {categories &&
          categories.length > 0 &&
          categories.map((category) => {
            return (
              <div className=" flex flex-col lg:flex-row md:w-11/12 justify-center items-center xs:w-full xs:m-2 md:m-4  border-t-2 dark:border-gray-700  shadow-xl lg:items-center rounded-2xl bg-white">
                <div className="flex justify-between w-full">
                  <div className="w-full p-2 m-4">
                    <div className="flex md:flex-row xs:flex-col w-full items-center">
                      <div className="flex justify-center text-2xl w-4/12 font-extrabold capitalize">
                        {category.title}
                      </div>
                      <div className="w-6/12 flex justify-center">
                        {category.description}
                      </div>
                      <div className="w-2/12 flex justify-center">
                        <div
                          className="p-1.5 bg-greenDark cursor-pointer"
                          onClick={() => handleEdit(category)}
                        >
                          <FaEdit color="white" />
                        </div>
                        <div
                          className="p-1.5 ml-3 bg-danger-700 cursor-pointer"
                          onClick={() => handleDelete(category._id)}
                        >
                          <FaTrashAlt color="white" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default DisplayCategory;
