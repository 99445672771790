import { get } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import {
  CATEGORY_ENDPOINT,
  GET_PRODUCT_DETAILS,
  UPLOAD_FILE,
} from "../../../../apiConst";
import InputField from "../../../../Common/InputField";
import SelectDropdown from "../../../../Common/SelectDropdown";
import {
  getRequest,
  postRequest,
  putRequest,
} from "../../../interceptor/interceptor";
import FullScreenLoader from "../../../Loader/FullScreenLoader";
import Dropzone from "react-dropzone";
import { FaPlusCircle } from "react-icons/fa";
import { toast } from "react-toastify";

const AddCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [images, setImages] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    if (get(location, "state.isEdit")) {
      setIsEdit(true);
      const stateObj = get(location, "state");
      reset({
        title: stateObj.title,
        description: stateObj.description,
        id: stateObj._id,
      });
    }
  }, []);

  const onSubmit = (data) => {
    // setIsLoading(true);
    console.log(data);
    if (isEdit) {
      putRequest(`${CATEGORY_ENDPOINT}?id=${data.id}`, data)
        .then((res) => {
          setIsLoading(false);
          navigate("/categories");
          toast.success("Category Edited Successfully");
        })
        .then((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      postRequest(`${CATEGORY_ENDPOINT}`, data)
        .then((res) => {
          setIsLoading(false);
          toast.success("Category Created Successfully");
          navigate("/categories");
        })
        .then((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const handleReset = () => {
    reset();
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className="flex w-full md:flex-row xs:flex-col font-headings leading-7 lg:leading-9">
        <div className="flex justify-center w-3/12">
          <button
            className="mx-1 mb-0 flex rounded border-2  px-6 pt-2 pb-[6px] text-sm font-medium uppercase leading-normal text-secondary-800 border-secondary-500 transition duration-150 ease-in-out hover:border-secondary-500  hover:bg-secondary-500 hover:text-white  focus:outline-none focus:ring-0 active:border-secondary-500 active:text-white "
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
        <div className="flex justify-center w-6/12 items-center space-y-2 flex-col">
          <h1 className="text-3xl lg:text-4xl font-semibold font-headings text-greenDark  ">
            Add New Category
          </h1>
        </div>
        <div className="w-3/12"></div>
      </div>
      <form className="w-full flex justify-center items-center flex-col p-5">
        <div className="flex justify-center md:w-1/3 xs:w-full items-center m-2">
          <div className="w-full">
            <InputField
              fieldName="title"
              validationObj={{
                required: "Please Enter Title",
              }}
              register={register}
              errors={errors}
              fieldType="text"
              placeholder="Title"
            />
          </div>
        </div>
        <div className="flex justify-center md:w-1/3 xs:w-full items-center m-2">
          <div className="w-full">
            <InputField
              fieldName="description"
              validationObj={{
                required: "Please Enter Description",
              }}
              register={register}
              errors={errors}
              fieldType="textarea"
              placeholder="Description"
            />
          </div>
        </div>
        <div className="flex md:flex-row xs:flex-col md:justify-between md:w-1/4 xs:justify-center">
          <div className="w-40">
            <button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              className="text-base font-extrabold md:m-1 xs:m-3 font-headings leading-none w-full rounded-3xl py-5 bg-greenDark border-greenDark border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green text-white"
            >
              {isEdit ? "Edit" : "Add"} Category
            </button>
          </div>
          <div className="w-40">
            <button
              type="reset"
              onClick={handleReset}
              className="text-base font-extrabold md:m-1 xs:m-3 font-headings leading-none w-full rounded-3xl py-5 bg-danger-600 border-dangbg-danger-600 border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-danger-400 text-white"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddCategory;
