import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "./Components/Admin/Admin Navbar/AdminNavbar";
import Navbar from "./Components/Navbar/Navbar";
const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState(null);

  const checkUserToken = () => {
    const userToken = localStorage.getItem("dairy_userAuthToken");
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      return navigate("/login");
    }

    if (props.isAdmin) {
      const typeOfUser = localStorage.getItem("dairy_userType");
      setUserType(typeOfUser);
      if (typeOfUser === "ADMIN") {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(true);
        return navigate("/home");
      }
    } else {
      const typeOfUser = localStorage.getItem("dairy_userType");
      setUserType(typeOfUser);
      setIsLoggedIn(true);
    }
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);
  return (
    <React.Fragment>
      {isLoggedIn ? (
        <>
          {userType === "ADMIN" ? (
            <>
              <AdminNavbar /> {props.children}
            </>
          ) : (
            <>
              <Navbar /> {props.children}
            </>
          )}{" "}
        </>
      ) : null}
    </React.Fragment>
  );
};
export default ProtectedRoute;
