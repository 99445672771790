const intialState = {
  wishListItems: [],
};

const wishlistReducer = (state = intialState, action) => {
  const { type, payload } = action;
  const { wishListItems } = state;
  switch (type) {
    case "ADD_TO_WISHLIST":
      console.log(payload, state);
      return { ...state, wishListItems: [...wishListItems, payload] };
    case "REMOVE_FROM_WISHLIST":
      const updatedWishlist = wishListItems.filter(
        (item) => item.productId !== payload
      );
      console.log(updatedWishlist);
      return { ...state, wishListItems: updatedWishlist };
    case "SET_WISHLIST_ITEM_FROM_STORAGE":
      return { ...state, wishListItems: payload };
    case "GET_ALL_WISHLIST_ITEMS":
      return { ...state, wishListItems: payload };
    default:
      return { ...state };
  }
};

export default wishlistReducer;
