import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import App from "./App";
import AdminDashboard from "./Components/Admin/Admin Dashboard";
import DisplayCategory from "./Components/Admin/Category";
import AddCategory from "./Components/Admin/Category/Add";
import AdminProducts from "./Components/Admin/Products";
import AddProducts from "./Components/Admin/Products/Add";
import Users from "./Components/Admin/Users";
import ShoppingCart from "./Components/Cart/index";
import Login from "./Components/Login";
import MyProfile from "./Components/My Profile";
import MyOrders from "./Components/MyOrders";
import OrderDetails from "./Components/MyOrders/OrderDetails";
import MyWishList from "./Components/MyWishlist";
import Navbar from "./Components/Navbar/Navbar";
import OrderConfirmation from "./Components/OrderConfirmation";
import OrderSummary from "./Components/OrderSummary";
import ProductDetails from "./Components/ProductDetail";
import AllProducts from "./Components/Products";
import SignUp from "./Components/SignUp";
import ProtectedRoute from "./protectedRoutes";

const NormalRoutes = (props) => {
  return (
    <>
      <Navbar />
      {props.children}
    </>
  );
};

const Routing = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/admin-dashboard"
          element={
            <ProtectedRoute isAdmin={true}>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedRoute isAdmin={true}>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="/all-products"
          element={
            <ProtectedRoute isAdmin={true}>
              <AdminProducts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-product"
          element={
            <ProtectedRoute isAdmin={true}>
              <AddProducts />
            </ProtectedRoute>
          }
        />
        <Route
          path="/categories"
          element={
            <ProtectedRoute isAdmin={true}>
              <DisplayCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-category"
          element={
            <ProtectedRoute isAdmin={true}>
              <AddCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-profile"
          element={
            <ProtectedRoute>
              <MyProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders"
          element={
            <ProtectedRoute>
              <MyOrders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/orders/:orderId"
          element={
            <ProtectedRoute>
              <OrderDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wishlist"
          element={
            <ProtectedRoute>
              <MyWishList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/home"
          element={
            <NormalRoutes>
              <App />
            </NormalRoutes>
          }
        />
        <Route
          path="/products/:productId"
          element={
            <NormalRoutes>
              <ProductDetails />
            </NormalRoutes>
          }
        />
        <Route
          path="/products"
          element={
            <NormalRoutes>
              <AllProducts />
            </NormalRoutes>
          }
        />

        <Route
          path="/cart"
          element={
            <NormalRoutes>
              <ShoppingCart />
            </NormalRoutes>
          }
        />
        <Route
          path="/orderSummary"
          element={
            <NormalRoutes>
              <OrderSummary />
            </NormalRoutes>
          }
        />
        <Route
          path="/orderConfirmation"
          element={
            <NormalRoutes>
              <OrderConfirmation />
            </NormalRoutes>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route
          path="/"
          element={
            <NormalRoutes>
              <App />
            </NormalRoutes>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
