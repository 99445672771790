import { useNavigate } from "react-router";
import img from "../../images/success.png";

const OrderConfirmation = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-center w-auto" style={{ height: "65vh" }}>
        <img src={img} />
      </div>
      <div className="flex justify-center mt-5">
        <h4 className="text-2xl font-headings font-extrabold">
          Your Order has been Confirmed
        </h4>
      </div>
      <div className="flex justify-center mt-10">
        <div className="w-60 max-w-sm">
          <button
            type="submit"
            onClick={() => navigate("/")}
            className="text-base font-extrabold font-headings leading-none w-full rounded-3xl py-5 bg-greenDark border-greenDark border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green text-white"
          >
            Go to Home
          </button>
        </div>
      </div>
    </>
  );
};

export default OrderConfirmation;
