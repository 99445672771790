import axios from "axios";

const apiURL = "https://sandbox.cashfree.com/pg/orders";
const appId = process.env.CASHFREE_APP_ID;
const secretKey = process.env.CASHFREE_SECRET_KEY;

const headers = {
  "Content-Type": "application/json",
  "x-client-id": "TEST350093264c4ebc3f6e39e372c1390053",
  "x-client-secret": "TESTbcca48dd9adb4d3d071d54f8c95ad3b4285c1b08",
  "x-api-version": "2022-09-01",
  "Access-Control-Allow-Origin": "*",
};

export const createCashfreeOrder = async (amount, currency, user, notes) => {
  const payload = {
    order_amount: amount,
    order_currency: currency,
    customer_details: {
      customer_id: user?._id || 123445,
      customer_name: user?.name,
      customer_email: user?.email,
      customer_phone: user?.phoneNumber,
    },
    order_meta: {
      notify_url: "https://test.cashfree.com",
    },
    order_note: notes,
  };

  try {
    const responseCashfreeOrder = await fetch(apiURL, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    });
    // const responseCashfreeOrder = await axios.post(apiURL, payload, {
    //   headers: headers,
    // });

    console.log("response cashfree - ", responseCashfreeOrder);

    if (responseCashfreeOrder.status === 200) {
      return responseCashfreeOrder.data;
    }
  } catch (error) {
    console.log("error in cashfree - ", error);
  }
};
