import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import storage from "redux-persist/lib/storage";
import { USER_ENDPOINT } from "../../apiConst";
import InputField from "../../Common/InputField";
import { saveDataInStorage } from "../../utils";
import { putRequest } from "../interceptor/interceptor";
import FullScreenLoader from "../Loader/FullScreenLoader";

const MyProfile = () => {
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    storage.getItem("login").then((res) => {
      if (res) {
        setUserInfo(JSON.parse(res));
      }
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      reset({
        name: userInfo.user.name,
        email: userInfo.user.email,
        phoneNumber: userInfo.user.phoneNumber,
      });
      saveDataInStorage("login", userInfo);
    }
  }, [userInfo]);

  const onSubmit = (data) => {
    setIsLoading(true);
    putRequest(`${USER_ENDPOINT}?id=${userInfo.user._id}`, data)
      .then((res) => {
        setIsLoading(false);
        toast.success("Profile Updated Successfully");
        setUserInfo({
          ...userInfo,
          user: {
            ...userInfo.user,
            name: data.name,
            phoneNumber: data.phoneNumber,
          },
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className="flex justify-center w-full items-center space-y-2 flex-col">
        <h1 className="text-3xl lg:text-4xl font-semibold font-headings text-greenDark leading-7 lg:leading-9 ">
          My Profile
        </h1>
      </div>
      <form className="w-full flex justify-center items-center flex-col p-5">
        <div className="flex justify-center w-1/3 items-center m-2">
          <div className="w-full">
            <InputField
              fieldName="name"
              validationObj={{
                required: "Please Enter Name",
              }}
              register={register}
              errors={errors}
              fieldType="text"
              className="min-h-[auto] w-full font-extrabold rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
              placeholder="Name"
            />
          </div>
        </div>
        <div className="flex justify-center w-1/3 items-center m-2">
          <div className="w-full">
            <InputField
              fieldName="email"
              validationObj={{
                required: "Please Enter Line 1",
              }}
              register={register}
              disabled={true}
              errors={errors}
              fieldType="text"
              className="min-h-[auto] w-full font-extrabold rounded border-1 bg-gray-300 py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
              placeholder="Flat / House No / Building"
            />
          </div>
        </div>
        <div className="flex justify-center w-1/3 items-center m-2">
          <div className="w-full">
            <InputField
              fieldName="phoneNumber"
              validationObj={{
                required: "Please Enter Phone Number",
              }}
              register={register}
              errors={errors}
              fieldType="text"
              className="min-h-[auto] w-full font-extrabold rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
              placeholder="Phone Number"
            />
          </div>
        </div>
        <div className="w-40">
          <button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            className="text-base font-extrabold font-headings leading-none w-full rounded-3xl py-5 bg-greenDark border-greenDark border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green text-white"
          >
            Update Profile
          </button>
        </div>
      </form>
    </>
  );
};

export default MyProfile;
