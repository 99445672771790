import storage from "redux-persist/lib/storage";

export const replaceSpaceWithDash = (str) => {
  return str.trim().replace(/\s+/g, "-");
};

export const saveDataInStorage = (key, value) => {
  storage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorageData = () => {
  localStorage.removeItem("dairy_userAuthToken");
  localStorage.removeItem("dairy_userType");
  localStorage.removeItem("dairy_userEmail");
  localStorage.removeItem("dairy_userName");
  localStorage.removeItem("dairy_userPhone");
  localStorage.removeItem("addressDetail");
};
