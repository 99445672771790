import React, { useEffect } from "react";
import { useState } from "react";
import { FaShoppingCart } from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router";
import { CATEGORY_ENDPOINT, GET_PRODUCT_DETAILS } from "../../apiConst";
import { getRequest } from "../interceptor/interceptor";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { useDispatch } from "react-redux";
import { addCartItem } from "../../actions/cart.action";
import { useSelector } from "react-redux";

function AllProducts() {
  const [productData, setProductData] = useState(null);
  const [allProductsData, setAllProductsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const cartproduct = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getProductDetails();
    getCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory && allProductsData.length > 0) {
      const data = allProductsData.filter(
        (product) => product?.category?.title === selectedCategory
      );
      setProductData(data);
    } else {
      setProductData(allProductsData);
    }
  }, [selectedCategory]);

  const getCategories = () => {
    setIsLoading(true);
    getRequest(`${CATEGORY_ENDPOINT}`)
      .then((res) => {
        if (res) {
          setCategories(res.categories);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getProductDetails = () => {
    setIsLoading(true);

    getRequest(`${GET_PRODUCT_DETAILS}`)
      .then((res) => {
        if (res && res.data.length > 0) {
          setProductData(res.data);
          setAllProductsData(res.data);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const addToCart = (product) => {
    dispatch(addCartItem(product));
  };

  const handleView = (id) => {
    navigate(`/products/${id}`);
  };

  const getCartButtonName = (id) => {
    if (cartproduct && cartproduct.length > 0) {
      const name = cartproduct.find((item) => item._id === id);
      if (name) {
        return "Added to Cart";
      } else {
        return "Add to Cart";
      }
    } else {
      return "Add to Cart";
    }
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}

      <div>
        <div className="flex justify-start font-headings md:w-full xs:w-11/12 md:flex-row xs:flex-col">
          <div
            onClick={() => setSelectedCategory("")}
            className={`p-2 px-5 ml-5 shadow-3xl border-2 cursor-pointer my-1 ${
              selectedCategory === ""
                ? `text-white bg-greenDark`
                : `border-greenDark hover:text-greenDark`
            }  rounded-xl `}
          >
            All
          </div>
          {categories &&
            categories.map((category) => {
              return (
                <div
                  onClick={() => setSelectedCategory(category.title)}
                  className={`p-2 px-5 ml-5 shadow-3xl border-2 cursor-pointer my-1 ${
                    selectedCategory === category.title
                      ? `text-white bg-greenDark`
                      : `border-greenDark hover:text-greenDark`
                  }  rounded-2xl `}
                >
                  {category.title}
                </div>
              );
            })}
        </div>
        {productData && (
          <>
            <div className="">
              <div className="w-full grid md:grid-cols-4 xs:grid-cols-1 content-center p-5">
                {productData &&
                  productData.length > 0 &&
                  productData.map((product, index) => {
                    return (
                      <div className=" items-center p-3" key={product._id}>
                        <div className="max-w-sm rounded overflow-hidden shadow-lg">
                          <Carousel
                            autoPlay={true}
                            showThumbs={false}
                            showArrows={true}
                          >
                            {product.image?.map((img, i) => {
                              return (
                                <div key={img + i}>
                                  <img
                                    alt={product.image[i]}
                                    src={img}
                                    className="w-full max-h-60"
                                  />
                                </div>
                              );
                            })}
                          </Carousel>
                          <div
                            onClick={() => handleView(product._id)}
                            className="px-6 py-4 flex justify-between cursor-pointer"
                          >
                            <div>
                              <div className="font-bold text-xl mb-2 font-headings">
                                {product.title}
                              </div>
                              <p className="text-gray-700 text-base font-paragraphs">
                                {product.description}
                              </p>
                            </div>
                            <div className="text-black font-extrabold text-xl">
                              ${product.price}
                            </div>
                          </div>
                          <div className="flex justify-between">
                            <button
                              onClick={() => addToCart(product)}
                              className="w-11/12 flex items-center justify-center rounded-3xl bg-greenDark hover:bg-green text-white p-3 m-6 ml-4 mr-4"
                            >
                              {getCartButtonName(product._id)}
                              <span className="ml-2">
                                <FaShoppingCart />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AllProducts;
