import { get, isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  FaEdit,
  FaEnvelope,
  FaPhoneAlt,
  FaTrashAlt,
  FaUserAlt,
} from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { removeAddressDetails } from "../../actions/address.action";
import BackButton from "../../Common/Back";
import { saveDataInStorage } from "../../utils";
import AddressDetails from "./AddressDetail";
import Payment from "./Payment";

const OrderSummary = () => {
  const [showAddressFields, setShowAddressFields] = useState(false);
  const [shipping, setShipping] = useState(30);
  const [total, setTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const cartproduct = useSelector((state) => state.cart);
  const addressDetail = useSelector((state) => state.addressDetail);
  const userLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const userName = localStorage.getItem("dairy_userName");
  const userEmail = localStorage.getItem("dairy_userEmail");
  const userPhone = localStorage.getItem("dairy_userPhone");

  const dispatch = useDispatch();

  useEffect(() => {
    setShowAddressFields(isEmpty(addressDetail.addressData));
    saveDataInStorage("addressDetail", addressDetail);
  }, [addressDetail]);

  const onEdit = () => {
    setShowAddressFields(true);
  };

  const onDelete = () => {
    dispatch(removeAddressDetails(userLoggedIn));
  };

  useEffect(() => {
    if (cartproduct && cartproduct.length > 0) {
      const amt = cartproduct.reduce((accumulator, object) => {
        return accumulator + object.price * object.quantity;
      }, 0);
      setGrandTotal(Number(amt) + Number(shipping));
      setTotal(amt);
    } else {
      setGrandTotal(0);
      setTotal(0);
    }
  }, [cartproduct]);

  return (
    <>
      <BackButton />
      <div className="py-8 px-4 md:px-6 2xl:px-20 2xl:container 2xl:mx-auto">
        <div className="flex justify-start item-start space-y-2 flex-col ">
          <h1 className="text-3xl lg:text-4xl font-semibold font-headings text-greenDark leading-7 lg:leading-9  text-gray-800">
            Order Summary
          </h1>
          <p className="text-base font-medium leading-6 text-gray-600">
            {moment(new Date()).format("DD MMM YYYY")}
          </p>
        </div>
        <div className="mt-10 flex flex-col xl:flex-row jusitfy-center items-stretch  w-full xl:space-x-8 space-y-4 md:space-y-6 xl:space-y-0">
          <div className="bg-gray-50 w-full xl:w-1/2 flex justify-between items-center md:items-start px-4 py-6 md:p-6 xl:p-8 flex-col ">
            <h3 className="text-xl font-semibold leading-5 text-green font-headings">
              Customer Details
            </h3>
            <div className="flex  flex-col md:flex-row xl:flex-col justify-start items-stretch h-full w-full md:space-x-6 lg:space-x-8 xl:space-x-0 ">
              <div className="flex flex-col justify-start items-start flex-shrink-0">
                {/* <div className="flex justify-center  w-full  md:justify-start items-center space-x-4 py-8 border-b border-gray-200">
                  <img
                    src="https://i.ibb.co/5TSg7f6/Rectangle-18.png"
                    alt="avatar"
                  />
                  <div className=" flex justify-start items-start flex-col space-y-2">
                    <p className="text-base font-semibold leading-4 text-left text-gray-800">
                      David Kent
                    </p>
                    <p className="text-sm leading-5 text-gray-600">
                      10 Previous Orders
                    </p>
                  </div>
                </div> */}
                <div className="flex justify-center  md:justify-start items-center space-x-4 py-4 border-b border-gray-200 w-full">
                  <FaUserAlt />
                  <p className="cursor-pointer text-sm leading-5 text-gray-800">
                    {userName}
                  </p>
                </div>
                <div className="flex justify-center  md:justify-start items-center space-x-4 py-4 border-b border-gray-200 w-full">
                  <FaPhoneAlt />
                  <p className="cursor-pointer text-sm leading-5 text-gray-800">
                    {userPhone}
                  </p>
                </div>
                <div className="flex justify-center  md:justify-start items-center space-x-4 py-4 border-b border-gray-200 w-full">
                  <FaEnvelope />
                  <p className="cursor-pointer text-sm leading-5 text-gray-800">
                    {userEmail}
                  </p>
                </div>
              </div>
              <div className="flex justify-between xl:h-full  items-stretch w-full flex-col mt-6 md:mt-0">
                <div className="flex justify-center md:justify-start xl:flex-col flex-col md:space-x-6 lg:space-x-8 xl:space-x-0 space-y-4 xl:space-y-6 md:space-y-0 md:flex-row  items-center md:items-start ">
                  <div className="flex justify-center md:justify-start  items-center md:items-start flex-col space-y-4 xl:mt-8">
                    <p className="text-base text-green font-semibold font-headings leading-4 text-center md:text-left text-gray-800">
                      Address
                    </p>
                  </div>
                  <div className="flex w-full justify-center md:justify-start  items-center md:items-start flex-col">
                    {!showAddressFields ? (
                      <div className="w-full justify-between flex border-[4px] border-greenDark rounded-xl p-5">
                        <div className="w-5/6">
                          <p className="text-base font-semibold leading-4 text-center md:text-left text-gray-800">
                            {get(addressDetail, "addressData.line1")},
                          </p>
                          <p className="w-48 lg:w-full xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                            {get(addressDetail, "addressData.line2")}
                          </p>
                          <p className="w-48 lg:w-full xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                            {get(addressDetail, "addressData.city")} ,{" "}
                            {get(addressDetail, "addressData.state")}
                          </p>
                          <p className="w-48 lg:w-full xl:w-48 text-center md:text-left text-sm leading-5 text-gray-600">
                            {get(addressDetail, "addressData.country")} ,{" "}
                            {get(addressDetail, "addressData.zipcode")}
                          </p>
                        </div>
                        <div className="flex items-center w-1/6 justify-end">
                          <button onClick={onEdit}>
                            <FaEdit />{" "}
                          </button>
                          <button className="ml-2" onClick={onDelete}>
                            <FaTrashAlt />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <AddressDetails showAddressFields={showAddressFields} />
                    )}
                  </div>
                </div>
                {/* <div className="flex w-full justify-center items-center md:justify-start md:items-start">
                  <button className="mt-6 md:mt-0 py-5 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 border border-gray-800 font-medium w-96 2xl:w-full text-base leading-4 text-gray-800">
                    Edit Details
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start w-full space-y-4 md:space-y-6 xl:space-y-8">
            <div className="flex flex-col justify-start items-start bg-gray-50 px-4 py-4 md:py-6 md:p-6 xl:p-8 w-full">
              <p className="text-lg md:text-xl text-green font-semibold font-headings leading-6 xl:leading-5 text-gray-800">
                Product Details
              </p>
              {cartproduct && cartproduct.length > 0 ? (
                cartproduct.map((item) => {
                  return (
                    <div className="mt-4 md:mt-6 flex  flex-col md:flex-row justify-start items-start md:items-center md:space-x-6 xl:space-x-8 w-full ">
                      <div className="pb-4 md:pb-8 w-full md:w-40">
                        <img
                          className="w-full hidden md:block"
                          src={item.image[0]}
                          alt="dress"
                        />
                      </div>
                      <div className="border-b border-gray-200 md:flex-row flex-col flex justify-between items-start w-full  pb-8 space-y-4 md:space-y-0">
                        <div className="w-full flex flex-col justify-start items-start space-y-8">
                          <h3 className="text-xl xl:text-2xl font-semibold leading-6 text-gray-800">
                            {get(item, "title")}
                          </h3>
                          <div className="flex justify-start items-start flex-col space-y-2">
                            {/* <p className="text-sm leading-none text-gray-800">
                            <span className="text-gray-300">Style: </span>{" "}
                            Italic Minimal Design
                          </p>
                          <p className="text-sm leading-none text-gray-800">
                            <span className="text-gray-300">Size: </span> Small
                          </p>
                          <p className="text-sm leading-none text-gray-800">
                            <span className="text-gray-300">Color: </span> Light
                            Blue
                          </p> */}
                          </div>
                        </div>
                        <div className="flex justify-between space-x-8 items-start w-full">
                          <p className="text-base xl:text-lg leading-6">
                            $ {get(item, "price")}
                          </p>
                          <p className="text-base xl:text-lg leading-6 text-gray-800">
                            {get(item, "quantity")}
                          </p>
                          <p className="text-base xl:text-lg font-semibold leading-6 text-gray-800">
                            $
                            {Number(get(item, "price")) * get(item, "quantity")}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
            <div className="flex justify-center md:flex-row flex-col items-stretch w-full space-y-4 md:space-y-0 md:space-x-6 xl:space-x-8">
              <div className="flex flex-col px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 space-y-6   ">
                <h3 className="text-xl text-green font-semibold leading-5 text-gray-800">
                  Summary
                </h3>
                <div className="flex justify-center items-center w-full space-y-4 flex-col border-gray-200 border-b pb-4">
                  <div className="flex justify-between  w-full">
                    <p className="text-base leading-4 text-gray-800">
                      Subtotal
                    </p>
                    <p className="text-base leading-4 text-gray-600">
                      ${total}
                    </p>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-base leading-4 text-gray-800">
                      Shipping
                    </p>
                    <p className="text-base leading-4 text-gray-600">$30.00</p>
                  </div>
                </div>
                <div className="flex justify-between items-center w-full">
                  <p className="text-base font-semibold leading-4 text-gray-800">
                    Total
                  </p>
                  <p className="text-base font-semibold leading-4 text-gray-600">
                    ${grandTotal}
                  </p>
                </div>
              </div>
              {/* <div className="flex flex-col justify-center px-4 py-6 md:p-6 xl:p-8 w-full bg-gray-50 space-y-6   ">
                <h3 className="text-xl font-semibold leading-5 text-gray-800">
                  Shipping
                </h3>
                <div className="flex justify-between items-start w-full">
                  <div className="flex justify-center items-center space-x-4">
                    <div className="w-8 h-8">
                      <img
                        className="w-full h-full"
                        alt="logo"
                        src="https://i.ibb.co/L8KSdNQ/image-3.png"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-center">
                      <p className="text-lg leading-6 font-semibold text-gray-800">
                        DPD Delivery
                        <br />
                        <span className="font-normal">
                          Delivery with 24 Hours
                        </span>
                      </p>
                    </div>
                  </div>
                  <p className="text-lg font-semibold leading-6 text-gray-800">
                    $30.00
                  </p>
                </div>
              </div> */}
            </div>
            <Payment
              userLoggedIn={userLoggedIn}
              grandTotal={grandTotal}
              userName={userName}
              userEmail={userEmail}
              userPhone={userPhone}
              cartproduct={cartproduct}
              addressDetail={addressDetail}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSummary;
