import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { FaShoppingCart, FaUserCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import storage from "redux-persist/lib/storage";
import { addAddressDetails } from "../../actions/address.action";
import { setCartItemFromStorage } from "../../actions/cart.action";
import { setLoginInfo } from "../../actions/login.action";
import { setWishlistItemFromStorage } from "../../actions/wishlist.action";
import logoImg from "../../images/logo.png";
import { removeLocalStorageData } from "../../utils";

function Navbar() {
  const [show, setShow] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const cartproduct = useSelector((state) => state.cart);
  const wishListItems = useSelector((state) => state.wishlist.wishListItems);
  const addressDetail = useSelector((state) => state.addressDetail);

  useEffect(() => {
    if (cartproduct.length === 0) {
      storage.getItem("cart").then((res) => {
        if (res) {
          dispatch(setCartItemFromStorage(JSON.parse(res)));
        }
      });
    }
    if (wishListItems.length === 0) {
      storage.getItem("wishlist").then((res) => {
        if (res) {
          dispatch(setWishlistItemFromStorage(JSON.parse(res)));
        }
      });
    }
    storage.getItem("login").then((res) => {
      if (res) {
        dispatch(setLoginInfo(JSON.parse(res)));
      }
    });
    if (isEmpty(addressDetail.addressData.length)) {
      storage.getItem("addressDetail").then((res) => {
        if (res) {
          dispatch(addAddressDetails(JSON.parse(res).addressData));
        }
      });
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("dairy_userAuthToken")) {
      setIsLogin(true);
    }
  }, [params]);

  const onClickDropDown = () => {
    setShow(!show);
  };

  const mobileMenu = () => {
    setShowMobileNav(!showMobileNav);
  };

  const handleLogout = () => {
    removeLocalStorageData();

    dispatch(
      setLoginInfo({
        login: false,
      })
    );
    dispatch(setWishlistItemFromStorage([]));
    storage.setItem(
      "login",
      JSON.stringify({
        login: false,
      })
    );
    navigate("/login");
  };

  return (
    <nav className="bg-transparent border-gray-200 lg:px-48 sm:px-4 py-2.5 dark:bg-gray-900 shadow-md mb-6">
      <div className="container flex flex-wrap items-center justify-between mx-auto">
        <Link to="/" className="flex items-center pl-1 md:pl-0">
          <img src={logoImg} className="h-8 mr-3" alt=" Logo" />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white font-roboto-mono">
            Dairy Farm
          </span>
        </Link>
        <div className="flex">
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={mobileMenu}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
          {/*Mobile Nav*/}
          {showMobileNav ? (
            <div className="w-full md:hidden" id="navbar-default">
              <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                <li>
                  <Link
                    to="/"
                    className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    className="flex items-center justify-between w-full py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100  md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono"
                    to="/products"
                  >
                    Products{" "}
                  </Link>
                </li>

                {isLogin ? (
                  <li>
                    <button
                      onClick={handleLogout}
                      className="ml-3 py-2 pl-3 pr-4 w-18 border-2 h-7 flex justify-center items-center  rounded-xl bg-danger-700 px-9 pt-4 pb-4 text-sm font-bold uppercase leading-normal text-white "
                    >
                      Logout
                    </button>
                  </li>
                ) : (
                  <li>
                    <Link
                      to="/login"
                      className="ml-3 py-2 pl-3 pr-4  border-2 h-7 flex justify-center items-center w-16  rounded-xl bg-primary-700 px-9 pt-4 pb-4 text-sm font-bold uppercase leading-normal text-white "
                    >
                      Login
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          ) : null}
          {/*Mobile Nav*/}
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <Link
                  to="/"
                  className="flex items-center leading-7 py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className="flex items-center leading-7 justify-between w-full py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100  md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent font-roboto-mono"
                  to={"/products"}
                >
                  Products{" "}
                </Link>
              </li>

              {isLogin ? (
                <li>
                  <button
                    onClick={handleLogout}
                    className="border-2 h-7 flex justify-center items-center w-14  rounded-xl bg-danger-700 px-9 pt-4 pb-4 text-sm font-bold uppercase leading-normal text-white "
                  >
                    Logout
                  </button>
                </li>
              ) : (
                <li>
                  <Link
                    to="/login"
                    className="border-2 h-7 flex justify-center items-center w-14  rounded-xl bg-primary-700 px-9 pt-4 pb-4 text-sm font-bold uppercase leading-normal text-white "
                  >
                    Login
                  </Link>
                </li>
              )}
            </ul>
          </div>
          <div className="flex">
            <Link to="/cart" className="m-auto ml-10 text-xl">
              <FaShoppingCart />
              <span
                className={`absolute md:top-5 xs:top-2 ml-2 ${
                  cartproduct.length > 0 ? "block" : "hidden"
                } rounded-full bg-danger-600 w-5 h-5 top right p-[2px] m-auto mr-10 text-white font-mono text-sm  leading-tight text-center`}
              >
                {cartproduct && cartproduct.length > 0
                  ? cartproduct.length
                  : ""}
              </span>
            </Link>
            {isLogin ? (
              <>
                <div
                  onClick={onClickDropDown}
                  className="m-auto ml-5 text-xl cursor-pointer"
                >
                  <FaUserCircle />
                </div>
                {show ? (
                  <div className="z-[100] mt-14 absolute font-normal bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 w-44">
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-400">
                      <li>
                        <Link
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          to="/my-profile"
                        >
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          to="/orders"
                        >
                          My Orders
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          to="/wishlist"
                        >
                          My Wishlist
                        </Link>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
