import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { GENERATE_OTP, VERIFY_OTP } from "../../apiConst";
import InputField from "../../Common/InputField";
import { postRequest } from "../interceptor/interceptor";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { toast } from "react-toastify";
import img from "../../images/logo.png";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { get_otp, loginAction, verify_otp } from "../../actions/login.action";
import { get } from "lodash";
import Navbar from "../Navbar/Navbar";
import storage from "redux-persist/lib/storage";
import { useSelector } from "react-redux";
import { saveDataInStorage } from "../../utils";
import {
  addItemToWishlist,
  setWishlistItemFromStorage,
} from "../../actions/wishlist.action";

const Login = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [otpFieldDisplay, setOtpFieldDisplay] = useState(false);
  const [googleUser, setGoogleUser] = useState([]);
  const [profile, setProfile] = useState(null);

  const userLoggedIn = useSelector((state) => state.login.isLoggedIn);

  const dispatch = useDispatch();

  useEffect(() => {
    if (googleUser && googleUser.access_token) {
      setIsLoading(true);
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${googleUser.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setIsLoading(false);
          setProfile(res.data);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, [googleUser]);

  useEffect(() => {
    if (profile) {
      const data = {
        email: profile.email,
      };
      loginViaEmailAndOTP(data);
    }
  }, [profile]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  let navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("dairy_userAuthToken")) {
      if (localStorage.getItem("dairy_userType") === "ADMIN") {
        navigate("/admin-dashboard");
      } else {
        navigate("/home");
      }
    }
  }, [userLoggedIn, navigate]);

  const signUp = () => {
    navigate("/signup");
  };

  const loginViaEmailAndOTP = (data) => {
    if (otpFieldDisplay) {
      data = {
        ...data,
        email: email,
      };
      dispatch(verify_otp(data))
        .then((res) => {
          const { user } = res.data.data;
          reset();
          console.log(res);
          dispatch(loginAction(res.data.data));
          const obj = {
            login: true,
            ...res.data.data,
          };

          storage.setItem("login", JSON.stringify(obj));
          const addressObj = {
            addressData: user?.address ? user.address : {},
          };
          saveDataInStorage("addressDetail", addressObj);
          dispatch(setWishlistItemFromStorage(get(user, "wishlist") || []));
          saveDataInStorage("wishlist", get(user, "wishlist") || []);
          toast.success("Login Successfully");
          setIsLoading(false);
          navigate("/");
        })
        .catch((err) => {
          setIsLoading(false);
          err.data && err.data.message && toast.error(err.data.message);
          console.log(err);
        });
    } else {
      setEmail(data.email);
      dispatch(get_otp(data))
        .then((res) => {
          setOtpFieldDisplay(true);
          reset();
          toast.success("Otp Sent Successfully");
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          err.data && err.data.message && toast.error(err.data.message);
          console.log(err);
        });
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);

    loginViaEmailAndOTP(data);
  };

  const loginToGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setGoogleUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });
  console.log(userLoggedIn);

  return (
    <>
      <Navbar />
      <section className="h-full lg:my-20">
        {isLoading && <FullScreenLoader />}
        <div className="container h-full px-6 py-24 xs:px-12">
          <div className="lg-6 flex h-full flex-wrap items-center justify-enter lg:justify-evenly xs:pl-20">
            <div className="mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xs:w-12/12">
              <div className="w-full text-4xl mb-5 font-extrabold text-muted">
                Welcome to the Dairy Farm
              </div>
              <img src={img} className="w-80" alt="Login" />
            </div>
            <div className="md:w-8/12 lg:ml-6 lg:w-5/12 mt-10">
              <div className="w-full text-4xl mb-5 font-extrabold text-muted">
                Login
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                {otpFieldDisplay ? (
                  <div className="relative mb-6">
                    <InputField
                      fieldName="otp"
                      validationObj={{ required: "Please Enter Otp to Verify" }}
                      register={register}
                      errors={errors}
                      fieldType="text"
                      className="min-h-[auto] w-full rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
                      placeholder="Enter Otp Here"
                    />
                  </div>
                ) : (
                  <div className="relative mb-6">
                    <InputField
                      fieldName="email"
                      validationObj={{ required: "Please Enter Email" }}
                      register={register}
                      errors={errors}
                      fieldType="text"
                      className="min-h-[auto] w-full rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
                      placeholder="Email or Phone Number"
                    />
                  </div>
                )}

                <div className="w-100 flex flex-row items-center justify-center pb-3">
                  <button
                    type="submit"
                    className="inline-block rounded bg-greenDark px-7 pt-3 pb-2.5 text-sm font-bold uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-greenDark-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-greenDark-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-greenDark-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    onClick={(e) => handleSubmit(onSubmit, e)}
                  >
                    {otpFieldDisplay ? "Verify OTP" : "Get Otp"}
                  </button>
                </div>

                <div className="w-100 flex flex-row items-center justify-center pt-3">
                  {!otpFieldDisplay && (
                    <button
                      type="button"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      onClick={() => loginToGoogle()}
                      className="mx-1 h-9 flex justify-center items-center w-96 rounded-full bg-greenDark uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-greenDark-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-greenDark-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-greenDark-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="mx-auto h-3.5 w-3.5"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"
                            id="mainIconPathAttribute"
                          ></path>
                        </svg>
                      </div>
                      <div className="ml-3 mt-0 font-bold text-sm capitalize">
                        Sign in With Google
                      </div>
                    </button>
                  )}
                </div>

                <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
                  <p className="mx-4 mb-0 text-center font-semibold dark:text-neutral-200">
                    OR
                  </p>
                </div>
              </form>

              <div className="flex items-center justify-center pb-6">
                <p className="mb-0 mr-2">New ? Create an account</p>
              </div>
              <div className="flex items-center justify-center ">
                <button
                  type="button"
                  className="inline-block rounded border-2 border-greenDark px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-greenDark transition duration-150 ease-in-out hover:border-greenDark-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-greenDark-600 focus:border-greenDark-600 focus:text-greenDark-600 focus:outline-none focus:ring-0 active:border-greenDark-700 active:text-greenDark-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  onClick={signUp}
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
