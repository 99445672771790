import { useEffect, useState } from "react";
import { FaRegUserCircle, FaUserCircle } from "react-icons/fa";
import { GET_ALL_USERS } from "../../../apiConst";
import { getRequest } from "../../interceptor/interceptor";
import FullScreenLoader from "../../Loader/FullScreenLoader";

const Users = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const getAllUsers = () => {
    setIsLoading(true);
    getRequest(`${GET_ALL_USERS}`)
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        setUsers(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className="flex justify-center w-full items-center space-y-2 flex-col">
        <h1 className="text-3xl lg:text-4xl font-semibold font-headings text-greenDark leading-7 lg:leading-9 ">
          All Users
        </h1>
      </div>
      <div className="m-2 p-5">
        {users &&
          users.length > 0 &&
          users.map((user) => {
            return (
              <div className=" flex flex-col lg:flex-row md:w-11/12 justify-center items-center xs:w-full xs:m-2 md:m-4  border-t-2 dark:border-gray-700  shadow-xl lg:items-center rounded-2xl bg-white">
                <div className="flex justify-between w-full">
                  <div className="flex justify-center items-center p-2 w-3/12">
                    {user?.image ? (
                      <span className="h-16 flex justify-center items-center">
                        <img src={user?.image} className="w-auto h-16" />
                      </span>
                    ) : (
                      <span className="h-16 flex justify-center items-center">
                        <FaRegUserCircle
                          fontSize={30}
                          color={"green"}
                        ></FaRegUserCircle>
                      </span>
                    )}
                  </div>
                  <div className="w-9/12 p-2 m-4">
                    <div className="flex md:flex-row xs:flex-col w-full items-center">
                      <div className="flex justify-center text-2xl w-4/12 font-extrabold capitalize">
                        {user.name}
                      </div>
                      <div className="w-4/12 flex justify-center">
                        {user.email}
                      </div>
                      <div className="w-4/12 flex justify-center">
                        {user.phoneNumber}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Users;
