const intialState = {
  addressData: {},
};

const addressDetailReducer = (state = intialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ADD_ADDRESS_DETAILS":
      return { ...state, addressData: payload };
    case "REMOVE_ADDRESS_DETAILS":
      return { ...state, addressData: payload };
    default:
      return { ...state };
  }
};

export default addressDetailReducer;
