import { add, get, isEmpty } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { Stepper } from "react-form-stepper";
import { FaCross } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router";
import FullScreenLoader from "../Loader/FullScreenLoader";

const steps = [
  { label: "Processing" },
  { label: "Shipping" },
  { label: "Delivery" },
];

const OrderDetails = () => {
  const [orderDetail, setOrderDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [trackingState, setTrackingState] = useState(0);
  const navigate = useNavigate();

  const params = useParams();
  const location = useLocation();

  const getOrderDetailsById = (orderId) => {};

  useEffect(() => {
    setIsLoading(true);
    if (
      get(location, "state") &&
      get(location, "state.fromOrderPage") === true
    ) {
      setOrderDetail(get(location, "state"));
    } else {
      getOrderDetailsById(params.orderId);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(orderDetail)) {
      setIsLoading(false);
      if (orderDetail?.status === "pending") {
        setTrackingState(0);
      } else if (orderDetail?.status === "shipped") {
        setTrackingState(1);
      } else {
        setTrackingState(2);
      }
    }
    console.log(orderDetail);
  }, [orderDetail]);

  const setAddress = (address) => {
    const addr = address.split("\n");
    return addr.map((item, i) => (
      <span className="leading-5">
        {item}
        {Number(i + 1) !== addr.length ? "," : ""} <br />
      </span>
    ));
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      {!isEmpty(orderDetail) ? (
        <div className="2xl:container 2xl:mx-auto py-4 px-4 md:px-6 xl:px-20">
          <div className="flex flex-col xl:flex-row justify-center items-center space-y-10 xl:space-y-0 xl:space-x-8">
            <div className="flex justify-center flex-col items-start w-full lg:w-9/12 xl:w-full ">
              <div className="flex justify-center w-full items-center space-y-2 flex-col">
                <h1 className="text-3xl lg:text-4xl font-semibold font-headings text-greenDark leading-7 lg:leading-9 ">
                  Order Summary
                </h1>
              </div>
              <div className="flex justify-between md:flex-row xs:flex-col w-full">
                <p className="text-lg leading-none mt-4 font-paragraphs text-gray-800">
                  Order Id:{" "}
                  <span className="font-semibold">{orderDetail.orderId}</span>
                </p>
                <p className="text-lg leading-none mt-4 font-paragraphs text-gray-800">
                  <div className="flex md:ml-2 xs:ml-0">
                    Ordered on :
                    <div className="ml-2 font-semibold">
                      {moment(orderDetail?.createdAt).format("DD-MM-YYYY")}
                    </div>
                    <div className="ml-2 font-semibold">
                      {moment(orderDetail?.createdAt).format("hh:mm:ss A")}
                    </div>
                  </div>
                </p>
              </div>
              <div className="flex justify-between md:flex-row xs:flex-col xs:m-3 w-full mt-5">
                <div className="md:w-1/4 md:my-2 xs:my-4 xs:w-11/12">
                  <h1 className="text-xl lg:text-2xl pl-2 font-semibold font-headings text-secondary-600 leading-7 lg:leading-9 ">
                    Shipping Address
                  </h1>
                  <div className="lg:flex-row w-full p-3 xs:m-2 border-t-4 dark:border-gray-700 items-start shadow-2xl lg:items-center rounded-2xl bg-white">
                    <p className="text-md capitalize min-h-[115px] font-semibold py-3 pl-1 leading-4 text-center md:text-left text-gray-800">
                      {setAddress(get(orderDetail, "shippingAddress"))}
                    </p>
                  </div>
                </div>

                <div className="md:w-1/4 xs:w-11/12 md:my-2 xs:my-4">
                  <h1 className="text-xl lg:text-2xl pl-2 font-semibold font-headings text-secondary-600 leading-7 lg:leading-9 ">
                    Order Status
                  </h1>
                  <div className="lg:flex-row w-full min-h-[115px] p-3 xs:m-2 border-t-4 dark:border-gray-700 items-start shadow-2xl lg:items-center rounded-2xl bg-white">
                    <Stepper
                      steps={steps}
                      activeStep={trackingState}
                      styleConfig={{
                        activeBgColor: "#5CB85C",
                        activeTextColor: "#fff",
                        completedBgColor: "#34A853",
                        completedTextColor: "#fff",
                        inactiveBgColor: "#eee",
                        inactiveTextColor: "#34A853",
                        fontWeight: 600,
                      }}
                    />
                  </div>
                </div>

                <div className="md:w-1/4 xs:w-11/12 md:my-2 xs:my-4">
                  <h1 className="text-xl lg:text-2xl pl-2 font-semibold font-headings text-secondary-600 leading-7 lg:leading-9 ">
                    Billing Address
                  </h1>
                  <div className="lg:flex-row w-full p-3 xs:m-2 border-t-4 dark:border-gray-700 items-start shadow-2xl lg:items-center rounded-2xl bg-white">
                    <p className="text-md capitalize min-h-[115px] font-semibold py-3 pl-1 leading-4 text-center md:text-left text-gray-800">
                      {setAddress(get(orderDetail, "shippingAddress"))}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-center w-full m-3">
                <div className="w-1/3"></div>
              </div>
              <div className="ml-2">
                <h1 className="text-xl lg:text-2xl pl-2 font-semibold font-headings text-secondary-600 leading-7 lg:leading-9 ">
                  Package Details
                </h1>
              </div>
              <div className="flex justify-center items-center w-full mt-8  flex-col space-y-4 ">
                {get(orderDetail, "items").map((item) => {
                  return (
                    <div className="flex font-paragraphs md:flex-row justify-start items-start md:items-center  border border-gray-200 w-11/12">
                      <div className="w-40 md:w-32">
                        <img
                          className="block"
                          src={get(item, "productId.image[0]")}
                          alt="product image"
                        />
                      </div>
                      <div className="flex justify-start md:justify-between  items-start md:items-center  md:flex-row w-full p-4 md:px-8">
                        <div className="flex flex-col md:flex-shrink-0 md:w-1/2 xs:w-11/12 justify-start items-start">
                          <h3 className="text-lg md:text-xl flex justify-between w-full  font-medium leading-6 md:leading-5  text-gray-800">
                            <div className="xs:w-9/12">
                              {get(item, "productId.title")}
                            </div>
                            <div className="md:mt-4 xs:mt-0 xs:w-3/12">
                              X {get(item, "quantity")}{" "}
                            </div>
                          </h3>
                          <div className="flex flex-row justify-start  space-x-4 md:space-x-6 items-start mt-2 md:block xs:hidden">
                            <p className="text-sm leading-none text-gray-600">
                              Description:
                              <span className="text-gray-800 font-medium">
                                {" "}
                                {get(item, "productId.description")}{" "}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="flex mt-4 md:mt-0 justify-end items-center w-full ">
                          <p className="text-xl lg:text-2xl font-normal leading-5 lg:leading-6 text-greenDark">
                            ${get(item, "price")}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-col justify-center items-center mt-8 xl:mt-10 space-y-10 w-full">
                <div className="flex flex-col space-y-4 w-11/12">
                  <div className="flex justify-between font-paragraphs items-center w-full">
                    <p className="md:text-2xl xs:text-xl font-semibold leading-4 text-secondary-600">
                      Grand Total
                    </p>
                    <p className="md:text-2xl font-semibold leading-4 md:mr-8 xs:mr-4 text-greenDark">
                      ${get(orderDetail, "amount")}
                    </p>
                  </div>
                  <div className="flex w-full justify-center items-center pt-1 md:pt-4  xl:pt-8 space-y-6 md:space-y-8 flex-col">
                    <button
                      className="text-base font-extrabold font-headings leading-none w-48 rounded-3xl py-3 bg-transparent border-greenDark border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greenDark text-greenDark hover:text-white hover:bg-greenDark"
                      onClick={() => navigate("/orders")}
                    >
                      Back to Orders
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default OrderDetails;
