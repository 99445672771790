import { get } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ORDER_ENDPOINT } from "../../apiConst";
import { postRequest } from "../interceptor/interceptor";
import FullScreenLoader from "../Loader/FullScreenLoader";
import OrderGrid from "./OrderGrid";

const MyOrders = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const getUserOrderDetails = () => {
    postRequest(`${ORDER_ENDPOINT}`)
      .then((res) => {
        setIsLoading(false);
        setOrders(get(res, "data.data"));
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getUserOrderDetails();
  }, []);

  const goToOrderDetail = (order) => {
    console.log(order);
    navigate(`/orders/${order.orderId}`, {
      state: {
        ...order,
        fromOrderPage: true,
      },
    });
  };

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className="flex justify-center w-full items-center space-y-2 flex-col">
        <h1 className="text-3xl lg:text-4xl font-semibold font-headings text-greenDark leading-7 lg:leading-9 ">
          My Orders
        </h1>
      </div>
      {orders &&
        orders.map((order) => {
          return <OrderGrid order={order} goToOrderDetail={goToOrderDetail} />;
        })}
    </>
  );
};

export default MyOrders;
