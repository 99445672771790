import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import storage from "redux-persist/lib/storage";
import {
  decreaseCartItemQuantity,
  increaseCartItemQuantity,
  removeCartItem,
  setCartItemFromStorage,
} from "../../actions/cart.action";
import {
  addItemToWishlist,
  removeItemFromWishlist,
  setWishlistItemFromStorage,
} from "../../actions/wishlist.action";
import { saveDataInStorage } from "../../utils";
import ShoppingCartDisplay from "./ShoppingCart";
import * as te from "tw-elements";
import GuestUserModal from "./GuestUserModal";
import { setLoginInfo } from "../../actions/login.action";
import { removeAddressDetails } from "../../actions/address.action";

const ShoppingCart = () => {
  const [total, setTotal] = useState(0);
  const [shipping, setShipping] = useState(30);
  const [grandTotal, setGrandTotal] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const myModal = new te.Modal(document.getElementById("previewForm"), {});

  const cartproduct = useSelector((state) => state.cart);
  const wishListItems = useSelector((state) => state.wishlist.wishListItems);
  const userLoggedIn = useSelector((state) => state.login.isLoggedIn);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const removeItem = (product) => {
    dispatch(removeCartItem(product._id));
  };

  useEffect(() => {
    console.log("UserLoggedIn", userLoggedIn);
  }, [userLoggedIn]);

  // useEffect(() => {
  //   if (cartproduct.length === 0) {
  //     storage.getItem("cart").then((res) => {
  //       if (res) {
  //         dispatch(setCartItemFromStorage(JSON.parse(res)));
  //       }
  //     });
  //   }
  //   if (wishListItems.length === 0) {
  //     storage.getItem("wishlist").then((res) => {
  //       if (res) {
  //         dispatch(setWishlistItemFromStorage(JSON.parse(res)));
  //       }
  //     });
  //   }
  //   storage.getItem("login").then((res) => {
  //     if (res) {
  //       dispatch(setLoginInfo(JSON.parse(res)));
  //     }
  //   });
  // }, []);

  useEffect(() => {
    setCartDataInStorage(cartproduct);
    if (cartproduct && cartproduct.length > 0) {
      const amt = cartproduct.reduce((accumulator, object) => {
        return accumulator + object.price * object.quantity;
      }, 0);
      setGrandTotal(Number(amt) + Number(shipping));
      setTotal(amt);
    } else {
      setGrandTotal(0);
      setTotal(0);
    }
  }, [cartproduct, refresh]);

  const increaseQuantity = (id, quantity, avaiableQuantity, e) => {
    e.stopPropagation();

    if (avaiableQuantity > quantity) {
      dispatch(increaseCartItemQuantity({ id: id }));
      setRefresh(!refresh);
    } else {
      toast.error("Maximum Quantity Exceeded");
    }
  };

  const decreaseQuantity = (id, quantity, e) => {
    e.stopPropagation();

    if (quantity > 1) {
      dispatch(decreaseCartItemQuantity({ id: id }));
      setRefresh(!refresh);
    } else if (quantity === 1) {
      dispatch(removeCartItem(id));
    }
  };

  const setCartDataInStorage = (cart) => {
    storage.setItem("cart", JSON.stringify(cart));
  };

  const addProductToWishlist = (product) =>
    dispatch(
      addItemToWishlist({
        productId: product._id,
        quantity: 1,
      })
    );

  const removeProductFromWishlist = (id) =>
    dispatch(removeItemFromWishlist(id));

  useEffect(() => {
    saveDataInStorage("wishlist", wishListItems);
  }, [wishListItems]);

  const handleView = (id) => {
    navigate(`/products/${id}`);
  };

  const onCheckout = () => {
    if (
      !userLoggedIn &&
      !localStorage.getItem("dairy_userName") &&
      !localStorage.getItem("dairy_userPhone")
    ) {
      dispatch(removeAddressDetails(userLoggedIn));
      myModal.show();
    } else {
      navigate("/orderSummary");
    }
  };

  return (
    <>
      <ShoppingCartDisplay
        cartproduct={cartproduct}
        total={total}
        grandTotal={grandTotal}
        shipping={shipping}
        wishListItems={wishListItems}
        decreaseQuantity={decreaseQuantity}
        increaseQuantity={increaseQuantity}
        removeItem={removeItem}
        addProductToWishlist={addProductToWishlist}
        removeProductFromWishlist={removeProductFromWishlist}
        handleView={handleView}
        onCheckout={onCheckout}
        userLoggedIn={userLoggedIn}
      />
      <GuestUserModal
        targetId={"previewForm"}
        title="Checkout Alert!"
        myModal={myModal}
      />
    </>
  );
};

export default ShoppingCart;
