import { get } from "lodash";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import InputField from "../../Common/InputField";
import * as te from "tw-elements";

const GuestUserModal = ({ targetId, title, myModal }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "Guest",
    },
  });

  const navigate = useNavigate();

  const onSubmit = (data) => {
    console.log(data);
    localStorage.setItem("dairy_userName", get(data, "name"));
    localStorage.setItem("dairy_userPhone", get(data, "phoneNumber"));
    localStorage.setItem("dairy_userEmail", get(data, "email"));
    myModal.hide();
    navigate("/orderSummary");
  };

  return (
    <>
      <div
        data-te-modal-init
        className="fixed top-0 left-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id={targetId}
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          data-te-modal-dialog-ref
          className="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[800px]"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
              <div className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                <h5 className="text-xl font-extrabold leading-normal text-neutral-800 dark:text-neutral-200">
                  {title}
                </h5>
                <button
                  type="button"
                  className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                  data-te-modal-dismiss
                  aria-label="Close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="relative flex-auto" data-te-modal-body-ref>
                <div className="md:h-[400px]  overflow-hidden overflow-y-scroll sm:h-auto">
                  <div className="flex justify-center flex-col items-center">
                    <div className="text-lg font-semibold">
                      This Section Requires User to Login
                    </div>
                    <button
                      data-te-modal-dismiss
                      onClick={() => navigate("/login")}
                      className="inline-block rounded bg-greenDark px-7 pt-3 pb-2.5 my-5 text-sm font-bold uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-greenDark-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-greenDark-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-greenDark-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                    >
                      Login
                    </button>
                    <div className="flex ">
                      <div
                        className="mr-3"
                        style={{
                          height: 3,
                          backgroundColor: "black",
                          width: "90px",
                          alignSelf: "center",
                        }}
                      ></div>
                      <span> OR </span>
                      <hr
                        className="ml-3"
                        style={{
                          height: 3,
                          backgroundColor: "black",
                          width: "90px",
                          alignSelf: "center",
                        }}
                      ></hr>
                    </div>
                    <div className="">
                      <div className="flex justify-center font-semibold my-4 text-lg">
                        Checkout as a Guest!
                      </div>
                      <div>
                        <InputField
                          fieldName="name"
                          validationObj={{ required: "Please Enter Name" }}
                          register={register}
                          errors={errors}
                          fieldType="text"
                          className="min-h-[auto] w-full font-extrabold rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
                          placeholder="Name"
                        />
                        <InputField
                          fieldName="phoneNumber"
                          validationObj={{
                            required: "Please Enter Phone Number",
                          }}
                          register={register}
                          errors={errors}
                          fieldType="text"
                          className="min-h-[auto] w-full font-extrabold rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
                          placeholder="Phone Number (Required)"
                        />
                        <InputField
                          fieldName="email"
                          register={register}
                          errors={errors}
                          fieldType="text"
                          className="min-h-[auto] w-full font-extrabold rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                <button
                  type="button"
                  className="inline-block rounded bg-danger-100 px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-danger-700 transition duration-150 ease-in-out hover:bg-danger-accent-100 focus:bg-danger-accent-100 focus:outline-none focus:ring-0 active:bg-danger-accent-200"
                  data-te-modal-dismiss
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onSubmit={handleSubmit(onSubmit)}
                  className="ml-1 inline-block rounded bg-success-600 px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-success-600-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-success-600-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-success-600-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                >
                  Continue as a Guest!
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default GuestUserModal;
