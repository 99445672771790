import { useEffect, useState } from "react";
import {
  FaEdit,
  FaPlus,
  FaRegUserCircle,
  FaTrash,
  FaTrashAlt,
  FaUserCircle,
} from "react-icons/fa";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { green } from "tailwindcss/colors";
import { GET_ALL_USERS, GET_PRODUCT_DETAILS } from "../../../apiConst";
import { deleteRequest, getRequest } from "../../interceptor/interceptor";
import FullScreenLoader from "../../Loader/FullScreenLoader";

const AdminProducts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const navigate = useNavigate();

  const getAllProducts = () => {
    setIsLoading(true);
    getRequest(`${GET_PRODUCT_DETAILS}`)
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        setProducts(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleEdit = (product) => {
    navigate("/add-product", {
      state: {
        ...product,
        isEdit: true,
      },
    });
  };

  const handleAdd = () => {
    navigate("/add-product");
  };

  const handleDelete = (id) => {
    setIsLoading(true);
    deleteRequest(`${GET_PRODUCT_DETAILS}?id=${id}`)
      .then((res) => {
        toast.success("Product Deleted Successfully");
        setIsLoading(false);
        getAllProducts();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className="flex w-full md:flex-row xs:flex-col font-headings leading-7 lg:leading-9">
        <div className="flex justify-center w-3/12">
          <button
            className="mx-1 mb-0 flex rounded border-2 border-greenDark px-6 pt-2 pb-[6px] text-sm font-medium uppercase leading-normal text-greenDark transition duration-150 ease-in-out hover:border-greenDark  hover:bg-greenDark hover:text-white  focus:outline-none focus:ring-0 active:border-greenDark active:text-white "
            onClick={() => handleAdd()}
          >
            <span className="mr-2 mt-[0.10rem]">
              <FaPlus />
            </span>
            Add Product
          </button>
        </div>
        <div className="flex justify-center w-6/12 items-center space-y-2 flex-col">
          <h1 className="text-3xl lg:text-4xl font-semibold font-headings text-greenDark  ">
            All Products
          </h1>
        </div>
        <div className="w-3/12"></div>
      </div>
      <div className="m-2 p-5">
        {products &&
          products.length > 0 &&
          products.map((product) => {
            return (
              <div className=" flex flex-col lg:flex-row md:w-11/12 justify-center items-center xs:w-full xs:m-2 md:m-4  border-t-2 dark:border-gray-700  shadow-2xl lg:items-center rounded-2xl bg-white">
                <div className="flex justify-between w-full">
                  <div className="flex justify-center items-center p-2 w-3/12">
                    {product?.image ? (
                      <span className="h-16 flex justify-center items-center">
                        <img src={product?.image} className="w-auto h-16" />
                      </span>
                    ) : (
                      <span className="h-16 flex justify-center items-center">
                        <FaRegUserCircle
                          fontSize={30}
                          color={"green"}
                        ></FaRegUserCircle>
                      </span>
                    )}
                  </div>
                  <div className="w-9/12 p-2 m-4">
                    <div className="flex md:flex-row xs:flex-col w-full items-center">
                      <div className="flex justify-center text-2xl w-3/12 font-extrabold capitalize">
                        {product.title}
                      </div>
                      <div className="w-3/12 flex justify-center">
                        {product.description}
                      </div>
                      <div className="w-2/12 flex justify-center">
                        {product.price}
                      </div>
                      <div className="w-2/12 flex justify-center">
                        {product.quantity}
                      </div>
                      <div className="w-2/12 flex justify-center">
                        <div
                          className="p-1.5 bg-greenDark cursor-pointer"
                          onClick={() => handleEdit(product)}
                        >
                          <FaEdit color="white" />
                        </div>
                        <div
                          className="p-1.5 ml-3 bg-danger-700 cursor-pointer"
                          onClick={() => handleDelete(product._id)}
                        >
                          <FaTrashAlt color="white" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default AdminProducts;
