import { get } from "lodash";
import moment from "moment";

const OrderGrid = ({ order, goToOrderDetail }) => {
  return (
    <>
      <div className="flex items-center justify-center md:w-full xs:w-11/12 xs:ml-5 md:ml-0 mt-5 ">
        <div className="flex flex-col lg:flex-row md:w-1/2 xs:w-full xs:m-2 border-t-4 dark:border-gray-700 items-start shadow-2xl lg:items-center rounded-2xl bg-white">
          <div className="w-full p-5 lg:w-full h-auto min-h-[170px] dark:bg-gray-800">
            <div className="flex justify-between font-headings">
              <div className="md:text-3xl xs:text-md font-extrabold">
                Order #{order.orderId}
              </div>
              <div className="flex md:text-xl xs:text-sm font-bold ">
                Order on :
                <div className="flex md:flex-row xs:flex-col ml-2">
                  <div>{moment(order?.createdAt).format("DD-MM-YYYY")}</div>
                  <div className="md:ml-2">
                    {moment(order?.createdAt).format("hh:mm:ss A")}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 xs:text-sm md:text-lg font-medium font-paragraphs">
              {get(order, "user.name")}
            </div>
            <div className="mt-1 xs:text-sm md:text-lg font-medium font-paragraphs">
              {get(order, "user.email")}
            </div>
            <div className="mt-1 xs:text-sm md:text-lg font-medium font-paragraphs">
              <div className="flex flex-row justify-between items-center">
                <div>Quantity : {get(order, "items").length || 0}</div>
                <div>Total Amount: ${get(order, "amount") || 0}</div>
              </div>
            </div>
            <div className="mt-2 xs:text-sm md:text-lg font-medium font-paragraphs">
              <div className="flex flex-row justify-between items-center">
                <button
                  type="submit"
                  onClick={() => goToOrderDetail(order)}
                  className="text-base font-extrabold font-headings leading-none w-48 rounded-3xl py-3 bg-transparent border-greenDark border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-greenDark text-greenDark hover:text-white hover:bg-greenDark"
                >
                  View Order Details
                </button>
                <div>
                  Status:{" "}
                  <span className="font-semibold">
                    {" "}
                    {get(order, "status").toUpperCase()}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="w-full lg:w-1/3 h-24 dark:border-gray-700 lg:h-64 border-t lg:border-t-0 lg:border-r lg:border-l lg:rounded-r dark:bg-gray-700 bg-gray-100"></div> */}
        </div>
      </div>
    </>
  );
};

export default OrderGrid;
