const intialState = {
  isLoggedIn: false,
  userType: null,
  userInfo: [],
};

const loginReducer = (state = intialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "LOGIN":
      return {
        ...state,
        isLoggedIn: true,
        userType: payload.userType,
        userInfo: payload.userInfo,
      };
    case "SET_LOGIN_INFO":
      return {
        ...state,
        isLoggedIn: payload.isLoggedIn,
        userInfo: payload.userInfo,
        userType: payload.userType,
      };
    case "LOGOUT":
      return { ...state, isLoggedIn: false, userType: null, userInfo: [] };
    default:
      return { ...state };
  }
};

export default loginReducer;
