import { useForm } from "react-hook-form";
import InputField from "../../Common/InputField";
import { Country, State, City } from "country-state-city";
import SelectDropdown from "../../Common/SelectDropdown";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addAddressDetails } from "../../actions/address.action";
import { useEffect } from "react";
import axios from "axios";
import { get } from "lodash";
import { toast } from "react-toastify";

const AddressDetails = ({ showAddressFields }) => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const dispatch = useDispatch();
  const addressDetail = useSelector((state) => state.addressDetail);
  const userLoggedIn = useSelector((state) => state.login.isLoggedIn);

  useEffect(() => {
    console.log(addressDetail.addressData);
    if (showAddressFields) {
      const { addressData } = addressDetail;
      reset(addressData);
      setStates(State.getStatesOfCountry(addressData.country));
      setCities(City.getCitiesOfState(addressData.country, addressData.state));
    }
  }, [showAddressFields]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({});

  const onCountryChange = (e) => {
    const { value } = e.target;
    if (value) {
      setValue("city", "");
      setValue("state", "");
      setValue("zipcode", "");
      setStates(State.getStatesOfCountry(value));
      setCities([]);
    }
  };

  const onZipCodeBlur = (e) => {
    const { value } = e.target;
    const fieldValues = getValues();
    if (value) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${value}&key=${process.env.REACT_APP_GEOLOCATION_API}`
      )
        .then((response) => response.json())
        .then((res) => {
          if (res?.results && res?.results.length > 0) {
            console.log(res?.results);
            const data = res.results[0];

            let obj = {};
            get(data, "address_components").forEach((addr) => {
              if (get(addr, "types").includes("locality")) {
                obj["city"] = get(addr, "long_name");
              } else if (
                get(addr, "types").includes("administrative_area_level_1")
              ) {
                obj["state"] = get(addr, "short_name");
              } else if (get(addr, "types").includes("country")) {
                obj["country"] = get(addr, "short_name");
              }
            });

            let countryVal = "";
            if (fieldValues.country === "") {
              setValue("country", obj.country);
              countryVal = obj.country;
            } else {
              countryVal = fieldValues.country;
            }

            if (states.length === 0) {
              setStates(State.getStatesOfCountry(countryVal));
            }
            setCities(City.getCitiesOfState(countryVal, obj.state));
            setTimeout(() => {
              setValue("city", obj.city);
              setValue("state", obj.state);
            }, 500);
          }
        });
    }
  };

  const onStateChange = (e) => {
    const { value } = e.target;
    const obj = getValues();
    if (value) {
      setCities(City.getCitiesOfState(obj.country, value));
      setValue("city", "");
      setValue("zipcode", "");
    }
  };

  // const getStateFromCity = (e) => {
  //   const { value } = e.target;
  //   const findVal = cities.find((data) => data.name.trim() === value.trim());
  //   if (findVal) {
  //     const stateCode = findVal.stateCode;
  //     if (stateCode) {
  //       const findState = states.find((data) => data.isoCode === stateCode);
  //       if (findState) {
  //         setValue("state", findState.isoCode);
  //       }
  //     }
  //   }
  // };

  const saveAddress = (data) => {
    let obj = null;
    if (userLoggedIn) {
      obj = {
        address: {
          ...data,
        },
      };
    }
    toast.success("Address Saved Successfully");
    dispatch(addAddressDetails(data, userLoggedIn, obj));
  };

  useEffect(() => {
    console.log("addressDetail", addressDetail);
  }, [addressDetail]);

  return (
    <form className="w-full" onSubmit={handleSubmit(saveAddress)}>
      <SelectDropdown
        fieldName="country"
        options={Country.getAllCountries()}
        optionsLabelKey="name"
        optionsValueKey="isoCode"
        register={register}
        onChange={onCountryChange}
        validationObj={{
          required: "Please Select Country",
        }}
        errors={errors}
        fieldType="select"
        className="min-h-[auto] w-full font-extrabold  rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
      />
      <InputField
        fieldName="line1"
        validationObj={{
          required: "Please Enter Line 1",
        }}
        register={register}
        errors={errors}
        fieldType="text"
        className="min-h-[auto] w-full font-extrabold rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
        placeholder="Flat / House No / Building"
      />
      <InputField
        fieldName="line2"
        register={register}
        errors={errors}
        fieldType="text"
        className="min-h-[auto] w-full font-extrabold rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
        placeholder="Area / Street / Sector"
      />
      <InputField
        fieldName="zipcode"
        register={register}
        onBlur={onZipCodeBlur}
        errors={errors}
        validationObj={{
          required: "Please Enter Zipcode",
        }}
        fieldType="text"
        className="min-h-[auto] w-full font-extrabold rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
        placeholder="Zipcode"
      />
      <div className="flex w-full flex-row mb-4">
        <div className="flex w-full mr-2 flex-col">
          <SelectDropdown
            fieldName="state"
            options={states}
            validationObj={{
              required: "Please Select State",
            }}
            optionsLabelKey="name"
            optionsValueKey="isoCode"
            register={register}
            onChange={onStateChange}
            errors={errors}
            fieldType="select"
            className="min-h-[auto] w-full  font-extrabold  rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
          />
        </div>
        <div className="flex w-full flex-col">
          <SelectDropdown
            fieldName="city"
            options={cities}
            validationObj={{
              required: "Please Select City",
            }}
            optionsLabelKey="name"
            optionsValueKey="name"
            register={register}
            // onChange={onStateChange}
            errors={errors}
            fieldType="select"
            className="min-h-[auto] w-full font-extrabold  rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
          />
        </div>
        {/* <InputField
          fieldName="city"
          validationObj={{
            required: "Please Enter City",
          }}
          register={register}
          errors={errors}
          fieldType="text"
          className="min-h-[auto] w-1/2 mr-2 font-extrabold rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
          placeholder="City"
          onBlur={getStateFromCity}
        /> */}
      </div>

      <button
        type="submit"
        onSubmit={handleSubmit(saveAddress)}
        className="text-base leading-none w-full rounded-3xl py-5 font-extrabold font-paragraphs bg-greenDark border-greenDark border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green text-white"
      >
        Save Address
      </button>
    </form>
  );
};

export default AddressDetails;
