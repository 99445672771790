import { useEffect, useState } from "react";
import { FaHeart, FaShoppingCart } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router";
import { addCartItem } from "../../actions/cart.action";
import { removeItemFromWishlist } from "../../actions/wishlist.action";
import { GET_PRODUCT_DETAILS } from "../../apiConst";
import { saveDataInStorage } from "../../utils";
import { getRequest } from "../interceptor/interceptor";
import FullScreenLoader from "../Loader/FullScreenLoader";

const MyWishList = () => {
  const [productData, setProductData] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const cartproduct = useSelector((state) => state.cart);
  const wishListItems = useSelector((state) => state.wishlist.wishListItems);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getProductDetails = () => {
    setIsLoading(true);

    getRequest(`${GET_PRODUCT_DETAILS}`).then((res) => {
      if (res && res.data.length > 0) {
        const productList = res.data;
        setAllProducts(productList);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const filterWishlistData = () => {
    if (allProducts?.length > 0 && wishListItems?.length > 0) {
      const result = allProducts.filter((o) =>
        wishListItems.some(({ productId }) => o._id === productId)
      );
      setProductData(result);
    }
  };

  const getCartButtonName = (id) => {
    if (cartproduct && cartproduct.length > 0) {
      const name = cartproduct.find((item) => item._id === id);
      if (name) {
        return "Added to Cart";
      } else {
        return "Move to Cart";
      }
    } else {
      return "Move to Cart";
    }
  };

  useEffect(() => {
    saveDataInStorage("wishlist", wishListItems);
    if (wishListItems) {
      if (allProducts.length > 0) {
        filterWishlistData();
      } else {
        getProductDetails();
      }
    }
  }, [wishListItems]);

  useEffect(() => {
    console.log(allProducts);
    if (allProducts.length > 0) {
      filterWishlistData();
    }
  }, [allProducts]);

  const handleView = (id) => {
    navigate(`/products/${id}`);
  };

  const addToCart = (product) => {
    dispatch(addCartItem(product));
  };

  const removeProductFromWishlist = (id) =>
    dispatch(removeItemFromWishlist(id));

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div className="flex justify-center w-full items-center space-y-2 flex-col">
        <h1 className="text-3xl lg:text-4xl font-semibold font-headings text-greenDark leading-7 lg:leading-9 ">
          My Wishlist
        </h1>
      </div>
      <div className="w-full grid md:grid-cols-4 xs:grid-cols-1 content-center p-5">
        {productData &&
          productData.length > 0 &&
          productData.map((product, index) => {
            return (
              <div className=" items-center p-3" key={product._id}>
                <div className="max-w-sm rounded overflow-hidden shadow-lg">
                  <Carousel
                    autoPlay={true}
                    showThumbs={false}
                    showArrows={true}
                  >
                    {product.image?.map((img, i) => {
                      return (
                        <div key={img + i}>
                          <img
                            alt={product.image[i]}
                            src={img}
                            className="w-full max-h-60"
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                  <div
                    onClick={() => handleView(product._id)}
                    className="px-6 py-4 flex justify-between cursor-pointer"
                  >
                    <div>
                      <div className="font-bold text-xl mb-2 font-headings">
                        {product.title}
                      </div>
                      <p className="text-gray-700 text-base font-paragraphs">
                        {product.description}
                      </p>
                    </div>
                    <div className="text-black font-extrabold text-xl">
                      ${product.price}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <button
                      onClick={() => addToCart(product)}
                      className="w-10/12 flex items-center justify-center rounded-3xl bg-greenDark hover:bg-green text-white p-3 m-6 ml-4 mr-4"
                    >
                      {getCartButtonName(product._id)}
                      <span className="ml-2">
                        <FaShoppingCart />
                      </span>
                    </button>
                    <button
                      onClick={() => addToCart(product)}
                      className="w-2/12 flex items-center justify-center text-2xl p-3 m-6 ml-4 mr-4"
                    >
                      <span className="ml-1">
                        <FaHeart
                          onClick={() => removeProductFromWishlist(product._id)}
                          color={"red"}
                        />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default MyWishList;
