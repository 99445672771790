export const LOGIN_API = "user/login";
export const GENERATE_OTP = "user/generateOtp";
export const VERIFY_OTP = "user/verify";
export const FORM_BUILDER_H_ADD = "formBuilder?db=";
export const FORM_BUILDER_D_ADD = "formBuilder/data";
export const FORM_BUILDER_GET_DATA = "oj";
export const GET_LATEST_RECORD = "latestRecord";
export const START_API = "formBuilder/start";
export const GET_IP_ADDRESS = "https://api.ipify.org/?format=json";
export const GET_CATEGORY = "formBuilder/category";
export const UPLOAD_FILE = "file/upload/?bucket=itPros";
export const DELETE_FILE = "remove-object";
export const GET_COMPANY_DETAILS = "oj/company";
export const WISHLIST_ENDPOINT = "wishlist";

export const GET_OJ_DETAILS = "oj";
export const GET_PRODUCT_DETAILS = "product";
export const USER_ENDPOINT = "user";
export const CHECKOUT_API = "checkout";
export const ORDER_ENDPOINT = "orders";
export const CATEGORY_ENDPOINT = "category";
export const ADMIN_DASHBOARD_ENDPOINT = "admin/dashboard";
export const GET_ALL_USERS = "admin/users";
