import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { GENERATE_OTP, VERIFY_OTP } from "../../apiConst";
import InputField from "../../Common/InputField";
import { postRequest } from "../interceptor/interceptor";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { toast } from "react-toastify";
import img from "../../images/logo.png";

const SignUp = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [otpFieldDisplay, setOtpFieldDisplay] = useState(false);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (profile) {
      const data = {
        email: profile.email,
      };
      loginViaEmailAndOTP(data);
    }
  }, [profile]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  let navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("dairy_userAuthToken")) {
      navigate(-1);
    }
  });

  const loginViaEmailAndOTP = (data) => {
    if (otpFieldDisplay) {
      data = {
        ...data,
        email: email,
      };
      postRequest(`${VERIFY_OTP}`, data)
        .then((res) => {
          reset();
          toast.success("Login Successfully");
          console.log(res);
          const { token } = res.data.data;
          if (token) {
            localStorage.setItem("dairy_userAuthToken", token);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          err.data && err.data.message && toast.error(err.data.message);
          console.log(err);
        });
    } else {
      setEmail(data.email);
      postRequest(`${GENERATE_OTP}`, data)
        .then((res) => {
          setOtpFieldDisplay(true);
          reset();
          toast.success("Otp Sent Successfully");
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          err.data && err.data.message && toast.error(err.data.message);
          console.log(err);
        });
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(data);
    // setIsLoading(true);
    // loginViaEmailAndOTP(data);
  };

  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <section className="h-full lg:my-20">
      {isLoading && <FullScreenLoader />}
      <div className="container h-full px-6 py-24 xs:px-12">
        <div className="lg-6 flex h-full flex-wrap items-center justify-enter lg:justify-evenly xs:pl-20">
          <div className="mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xs:w-12/12">
            <div className="w-full text-4xl mb-5 font-extrabold text-muted">
              Welcome to the Dairy Farm
            </div>
            <img src={img} className="w-80" alt="Login" />
          </div>
          <div className="md:w-8/12 lg:ml-6 lg:w-5/12 mt-10">
            <div className="w-full text-4xl mb-5 font-extrabold text-muted">
              Sign Up
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              {otpFieldDisplay ? (
                <div className="relative mb-6">
                  <InputField
                    fieldName="otp"
                    validationObj={{ required: "Please Enter Otp to Verify" }}
                    register={register}
                    errors={errors}
                    fieldType="text"
                    className="min-h-[auto] w-full rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
                    placeholder="Enter Otp Here"
                  />
                </div>
              ) : (
                <div className="relative mb-6">
                  <InputField
                    fieldName="name"
                    validationObj={{ required: "Please Enter Name" }}
                    register={register}
                    errors={errors}
                    fieldType="text"
                    className="min-h-[auto] w-full rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
                    placeholder="Name"
                  />
                  <InputField
                    fieldName="phone"
                    validationObj={{ required: "Please Enter Phone" }}
                    register={register}
                    errors={errors}
                    fieldType="text"
                    className="min-h-[auto] w-full rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
                    placeholder="Phone Number"
                  />
                  <InputField
                    fieldName="email"
                    validationObj={{ required: "Please Enter Email" }}
                    register={register}
                    errors={errors}
                    fieldType="text"
                    className="min-h-[auto] w-full rounded border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
                    placeholder="Email"
                  />
                </div>
              )}

              <div className="w-100 flex flex-row items-center justify-center pb-3">
                <button
                  type="submit"
                  className="inline-block rounded bg-greenDark px-7 pt-3 pb-2.5 text-sm font-bold uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-greenDark-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-greenDark-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-greenDark-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  onClick={(e) => handleSubmit(onSubmit, e)}
                >
                  {otpFieldDisplay ? "Verify OTP" : "Sign Up"}
                </button>
              </div>

              <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
                <p className="mx-4 mb-0 text-center font-semibold dark:text-neutral-200">
                  OR
                </p>
              </div>
            </form>

            <div className="flex items-center justify-center pb-6">
              <p className="mb-0 mr-2">Already have an account ?</p>
            </div>
            <div className="flex items-center justify-center ">
              <button
                type="button"
                className="inline-block rounded border-2 border-greenDark px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-greenDark transition duration-150 ease-in-out hover:border-greenDark-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-greenDark-600 focus:border-greenDark-600 focus:text-greenDark-600 focus:outline-none focus:ring-0 active:border-greenDark-700 active:text-greenDark-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                data-te-ripple-init
                data-te-ripple-color="light"
                onClick={goToLogin}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
