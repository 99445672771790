import React from "react";
import { Carousel } from "react-responsive-carousel";

function ProductSlider({ productData }) {
  const { image } = productData;

  return (
    <div className="w-full">
      {image && image.length > 0 && (
        <Carousel autoPlay={true} showThumbs={false} showArrows={true}>
          {image?.map((img, i) => {
            return (
              <div>
                <img
                  alt={productData.title}
                  src={img}
                  className="w-full"
                  style={{ height: "50vh" }}
                />
              </div>
            );
          })}
        </Carousel>
      )}
    </div>
  );
}

export default ProductSlider;
