import { get } from "lodash";
import React from "react";
import BackButton from "../../Common/Back";
import receiptImg from "../../images/svg/receipt.svg";
import emptyCartImg from "../../images/svg/emptyCart.svg";
import { FaHeart } from "react-icons/fa";

const ShoppingCartDisplay = ({
  cartproduct,
  total,
  grandTotal,
  shipping,
  wishListItems,
  increaseQuantity,
  decreaseQuantity,
  removeItem,
  addProductToWishlist,
  removeProductFromWishlist,
  handleView,
  onCheckout,
  userLoggedIn,
}) => {
  const addToWishlistBtn = (item) => {
    return (
      <p
        className="flex text-xs leading-3 underline text-gray-800 cursor-pointer"
        onClick={() => addProductToWishlist(item)}
      >
        <FaHeart color="gray" />
        <span className="ml-2">Add to Wishlist </span>
      </p>
    );
  };

  const removeFromWishlistBtn = (item) => {
    return (
      <p
        className="flex text-xs leading-3 underline text-gray-800 cursor-pointer"
        onClick={() => removeProductFromWishlist(item._id)}
      >
        <FaHeart color="red" />{" "}
        <span className="ml-2"> Remove from Wishlist </span>
      </p>
    );
  };

  const getWishListBtn = (item) => {
    if (wishListItems && item) {
      const found = wishListItems?.find(
        (wishlistItem) => wishlistItem.productId === item._id
      );
      console.log(found);
      if (found) {
        return removeFromWishlistBtn(item);
      } else {
        return addToWishlistBtn(item);
      }
    } else {
      return removeFromWishlistBtn;
    }
  };

  return (
    <>
      <div>
        <div className="w-full h-full bg-black " id="chec-div">
          <div
            className="w-full absolute z-10 right-0 h-full overflow-x-hidden transform translate-x-0 transition ease-in-out duration-700"
            id="checkout"
          >
            <div className="flex md:flex-row flex-col justify-center" id="cart">
              <div
                className="lg:w-1/2 w-full md:pl-10 pl-4 pr-10 md:pr-4 md:py-12 py-2 bg-white overflow-y-auto overflow-x-hidden h-screen"
                id="scroll"
              >
                <div style={{ marginLeft: "-10px" }}>
                  <BackButton />
                </div>
                <p className="text-5xl font-black font-headings leading-10 text-gray-800 pt-4 mb-0">
                  Cart
                </p>
                {cartproduct && cartproduct.length > 0 ? (
                  cartproduct.map((item) => {
                    return (
                      <div
                        className="md:flex items-center mt-14 py-8 border-t border-gray-200"
                        key={item._id}
                      >
                        <div
                          className="w-1/4 cursor-pointer"
                          onClick={() => handleView(item._id)}
                        >
                          <img
                            src={get(item, "image[0]")}
                            alt={item.title}
                            className="w-full max-h-40 object-center object-cover"
                          />
                        </div>
                        <div className="md:pl-3 md:w-3/4">
                          <p className="text-xs leading-3 text-gray-800 md:pt-0 pt-4">
                            {get(item, "category.title")}
                          </p>
                          <div
                            className="flex items-center justify-between w-full pt-1 cursor-pointer"
                            onClick={() => handleView(item._id)}
                          >
                            <p className="text-base font-black font-headings leading-none text-gray-800">
                              {get(item, "title")}
                            </p>
                            <div className="product-number-input h-10 w-32 mt-5">
                              <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                                <button
                                  data-action="decrement"
                                  className="rounded-full px-3 py-1.5 text-xl text-center bg-secondary-300"
                                  onClick={(e) =>
                                    decreaseQuantity(
                                      get(item, "_id"),
                                      get(item, "quantity"),
                                      e
                                    )
                                  }
                                >
                                  <span className="font-extrabold">−</span>
                                </button>
                                <input
                                  type="number"
                                  className="outline-none border-none focus:outline-none text-center w-full"
                                  name="custom-input-number"
                                  disabled={true}
                                  value={item.quantity}
                                ></input>
                                <button
                                  data-action="increment"
                                  className="rounded-full px-3 py-1.5 text-xl text-center bg-secondary-300"
                                  onClick={(e) =>
                                    increaseQuantity(
                                      get(item, "_id"),
                                      get(item, "quantity"),
                                      get(item, "avaiableQuantity"),
                                      e
                                    )
                                  }
                                >
                                  <span className="m-auto font-extrabold">
                                    +
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <p className="text-xs leading-3 text-gray-600 pt-2 font-paragraphs">
                            {get(item, "description")}
                          </p>
                          <div className="flex items-center justify-between pt-5 pr-6">
                            <div className="flex itemms-center">
                              {userLoggedIn && getWishListBtn(item)}
                              <p
                                onClick={() => removeItem(item)}
                                className="text-xs leading-3 underline text-red-500 pl-5 cursor-pointer"
                              >
                                Remove
                              </p>
                            </div>
                            <p className="text-base font-black leading-none text-gray-800">
                              ${get(item, "price")}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <>
                    <img src={emptyCartImg} className="h-[80vh] opacity-40" />
                  </>
                )}
              </div>
              <div className="xl:w-1/2 md:w-1/3  w-full bg-secondary-100 h-full">
                <div className="flex flex-col md:h-screen px-14 py-8 justify-between overflow-y-auto">
                  <div>
                    <p className="text-4xl font-extrabold leading-9 text-secondary-900 font-headings">
                      Summary
                    </p>
                    <div className="flex items-center justify-between pt-16 font-headings">
                      <p className="text-xl font-extrabold  leading-none text-secondary-900">
                        Subtotal
                      </p>
                      <p className="text-xl font-extrabold  leading-none text-secondary-900">
                        ${total}
                      </p>
                    </div>
                    <div className="flex items-center justify-between pt-5 font-headings">
                      <p className="text-xl font-extrabold leading-none text-secondary-900">
                        Shipping
                      </p>
                      <p className="text-xl font-extrabold  leading-none text-secondary-900">
                        ${shipping}
                      </p>
                    </div>
                  </div>
                  <div className="flex opacity-30 justify-center items-center ">
                    <img src={receiptImg} className="h-[50vh]" />
                  </div>
                  <div>
                    <div className="flex items-center pb-6 justify-between lg:pt-5 pt-10 font-headings">
                      <p className="text-2xl font-extrabold  leading-normal text-gray-800">
                        Grand Total
                      </p>
                      <p className="text-2xl font-extrabold  leading-normal text-right text-gray-800">
                        ${grandTotal}
                      </p>
                    </div>
                    <button
                      onClick={onCheckout}
                      className="text-base leading-none w-full rounded-3xl py-5 bg-greenDark border-greenDark border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green text-white"
                    >
                      Checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>
        {` /* width */
                #scroll::-webkit-scrollbar {
                    width: 3px;
                }

                /* Track */
                #scroll::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                /* Handle */
                #scroll::-webkit-scrollbar-thumb {
                    background: rgb(133, 132, 132);
                }
`}
      </style>
    </>
  );
};

export default ShoppingCartDisplay;
