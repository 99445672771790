const cartReducer = (state = [], action) => {
  let done = false;
  switch (action.type) {
    case "ADD_TO_CART":
      state.map((item, index) => {
        if (item._id === action.payload._id) {
          done = true;
          if (item.avaiableQuantity > item.quantity) {
            state[index].quantity = state[index].quantity + action.quantity;
          } else {
            console.log("out of stock");
          }
          return state;
        }
      });
      if (!done) {
        const obj = [
          ...state,
          {
            _id: action.payload._id,
            category: action.payload.category,
            createdAt: action.payload.createdAt,
            description: action.payload.description,
            image: action.payload.image,
            price: action.payload.price,
            sku: action.payload.sku,
            title: action.payload.title,
            updatedAt: action.payload.updatedAt,
            avaiableQuantity: action.payload.quantity,
            quantity: action.quantity,
          },
        ];
        return obj;
      }
      return;

    case "REMOVE_FROM_CART":
      return state.filter((item) => item._id !== action.payload);

    case "SET_CART_ITEM_FROM_STORAGE":
      return [...action.payload];

    case "INCREASE_CART_ITEM_QUANTITY":
      state.map((item, index) => {
        if (item._id === action.payload.id) {
          const qty = Number(state[index].quantity) + 1;
          state[index].quantity = qty;
          return state;
        }
      });
      return state;

    case "DECREASE_CART_ITEM_QUANTITY":
      state.map((item, index) => {
        if (item._id === action.payload.id) {
          state[index].quantity = state[index].quantity - 1;
          return state;
        }
      });
      return state;

    case "EMPTY_CART":
      state.splice(0, state.length);
      return state;

    default:
      return state;
  }
};

export default cartReducer;
