import { FaChevronCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router";

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <div
      className="ml-5 flex items-center text-gray-500 hover:text-gray-600 cursor-pointer"
      onClick={() => navigate(-1)}
    >
      <FaChevronCircleLeft />
      <p className="text-sm pl-2 leading-none">Back</p>
    </div>
  );
};

export default BackButton;
