import { WISHLIST_ENDPOINT } from "../apiConst";
import {
  deleteRequest,
  postRequest,
} from "../Components/interceptor/interceptor";

export const addItemToWishlist = (payload) => (dispatch) => {
  return postRequest(`${WISHLIST_ENDPOINT}`, payload).then((res) => {
    dispatch({ type: "ADD_TO_WISHLIST", payload });
  });
};

export const removeItemFromWishlist = (id) => (dispatch) => {
  return deleteRequest(`${WISHLIST_ENDPOINT}?id=${id}`).then((res) => {
    dispatch({ type: "REMOVE_FROM_WISHLIST", payload: id });
  });
};

export const setWishlistItemFromStorage = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_WISHLIST_ITEM_FROM_STORAGE", payload: data });
  };
};
