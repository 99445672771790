import { combineReducers } from "redux";
import loginReducer from "./login.reducer";
import cartReducer from "./cart.reducer";
import wishlistReducer from "./wishlist.reducer";
import addressDetailReducer from "./address.reducer";

const rootReducer = combineReducers({
  login: loginReducer,
  cart: cartReducer,
  wishlist: wishlistReducer,
  addressDetail: addressDetailReducer,
});

export default rootReducer;
