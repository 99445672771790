import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routing from "./routes";
import { configStore, persistor } from "./store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import "./css/styles.css";
import * as te from "tw-elements";
import "./../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

const store = configStore({});

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_WEB_CLIENT_ID}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Routing />
      </PersistGate>
      <ToastContainer theme="colored" />
    </Provider>
  </GoogleOAuthProvider>
);

reportWebVitals();
