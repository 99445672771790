import React from "react";
// import { Label, FormGroup, Row, Col, Input } from "reactstrap";

const InputField = ({
  fieldId,
  fieldName,
  fieldLabel,
  fieldType,
  register,
  validationObj,
  placeholder,
  onChange,
  onBlur,
  className = "min-h-[auto] shadow appearance-none border-2 border-gray-200 rounded placeholder-gray-600 placeholder-opacity-80 disabled:bg-gray-200  w-full py-2.5 px-4 text-black leading-tight focus:outline-none focus:bg-white focus:border-primary-500",
  errors,
  children,
  multiple,
  required,
  disabled,
  value,
  onClick,
}) => {
  return (
    <>
      {fieldType === "textarea" ? (
        <textarea
          name={fieldName}
          id={fieldId}
          {...register(fieldName, {
            onChange: (e) => onChange && onChange(e),
            onBlur: (e) => onBlur && onBlur(e),
            ...validationObj,
          })}
          className={className}
          placeholder={placeholder}
          disabled={disabled}
        />
      ) : (
        <input
          id={fieldId}
          name={fieldName}
          {...register(fieldName, {
            onChange: (e) => onChange && onChange(e),
            onBlur: (e) => onBlur && onBlur(e),
            ...validationObj,
          })}
          type={fieldType}
          className={className}
          placeholder={placeholder}
          // onChange={onChange}
          disabled={disabled}
        />
      )}

      <p className="py-2 text-danger">
        {errors[fieldName] && errors[fieldName].message}
      </p>
    </>
  );
};

export default InputField;
