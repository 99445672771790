import { get } from "lodash";
import { GENERATE_OTP, VERIFY_OTP } from "../apiConst";
import { postRequest } from "../Components/interceptor/interceptor";

export const loginAction = (data) => {
  const { token, user } = data;
  localStorage.setItem("dairy_userAuthToken", token);
  localStorage.setItem("dairy_userType", get(user, "userType"));
  localStorage.setItem("dairy_userName", get(user, "name"));
  localStorage.setItem("dairy_userPhone", get(user, "phoneNumber"));
  localStorage.setItem("dairy_userEmail", get(user, "email"));
  return (dispatch) => {
    dispatch({
      type: "LOGIN",
      payload: { userType: get(user, "userType"), userInfo: user },
    });
  };
};

export const setLoginInfo = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_LOGIN_INFO",
      payload: {
        isLoggedIn: data.login,
        userInfo: get(data, "user") || [],
        userType: get(data, "user.userType") || "",
      },
    });
  };
};

export const get_otp = (data) => (dispatch) =>
  postRequest(`${GENERATE_OTP}`, data).then((response) => {
    return response;
  });

export const verify_otp = (data) => (dispatch) =>
  postRequest(`${VERIFY_OTP}`, data).then((response) => {
    return response;
  });
