import { get } from "lodash";
import storage from "redux-persist/lib/storage";
import { USER_ENDPOINT } from "../apiConst";
import { putRequest } from "../Components/interceptor/interceptor";

export const addAddressDetails = (obj, userLoggedIn, addressObj) => {
  if (userLoggedIn) {
    return (dispatch) =>
      storage.getItem("login").then((res) => {
        if (res) {
          res = JSON.parse(res);
          const id = get(res, "user._id");

          return putRequest(`${USER_ENDPOINT}?id=${id}`, addressObj).then(
            (res) => {
              dispatch({ type: "ADD_ADDRESS_DETAILS", payload: obj });
            }
          );
        } else {
          return dispatch({ type: "ADD_ADDRESS_DETAILS", payload: obj });
        }
      });
  } else {
    return (dispatch) => {
      dispatch({ type: "ADD_ADDRESS_DETAILS", payload: obj });
    };
  }
};

export const removeAddressDetails = (userLoggedIn) => {
  if (userLoggedIn) {
    return (dispatch) =>
      storage.getItem("login").then((res) => {
        if (res) {
          res = JSON.parse(res);
          const id = get(res, "user._id");

          return putRequest(`${USER_ENDPOINT}?id=${id}`, {
            address: {},
          }).then((res) => {
            dispatch({ type: "REMOVE_ADDRESS_DETAILS", payload: {} });
          });
        } else {
          return dispatch({ type: "REMOVE_ADDRESS_DETAILS", payload: {} });
        }
      });
  } else {
    return (dispatch) => {
      dispatch({ type: "REMOVE_ADDRESS_DETAILS", payload: {} });
    };
  }
};
